import CustomButton from "../custom-components/custom-button";
import Header from "./Header";

const PaymentInProgress = ({ storeData, isOnline, paymentError, onClickBack, onClickTryAgain }) => {
    
    return <div className="checkout-page payment-progress">
        <Header storeData={storeData} />
        {!isOnline ? 
            <div className="box-container connection-status mt-rem-5">
                {/* <BsCloudSlash size={40}/> */}
                <h2>You are currently offline</h2>
                <p>Please, make sure you have stable internet connection</p>
            </div>
            : <>
                { paymentError ? 
                    <div className="box-container payment-error">
                        <p className="text-bold progress-text">{(typeof paymentError === 'string' && paymentError !== '') ? paymentError : 'Payment not successful'}</p>
                        <CustomButton className="btn-block" type="button" onClick={onClickTryAgain}>Try Again</CustomButton>
                        <CustomButton className="btn-block" type="button" onClick={onClickBack}>Back to Checkout Page</CustomButton>
                    </div>
                : <div className="box-container payment-in-progress">
                    <p className="text-bold progress-text">Payment in Progress</p>
                    <p className="text-bold instruction-text">Please follow the instructions on the card reader</p>
                    <p className="text-bold instruction-text-content">Press X on the card reader to cancel the payment</p>
                </div>
                }
            </>
        }
    </div>
}

export default PaymentInProgress;