import React, { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { apiService } from "../../services";
import { emptyCart } from "../../store/actions/ProductActions";
import { setErrorMessage, setStoreData } from "../../store/actions/StoreActions";
import { setUser } from "../../store/actions/UserActions";
import jwtService from "../../services/jwtService";

const ComponentWrapper = (props) => {
    const mountRef = useRef(false);
    const { storeId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        mountRef.current = true;
        if(props.storeUser && props.storeUser.id){
            fetchCurrentUser();
        }
        return () => {
            mountRef.current = false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        fetchStoreDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[storeId]);

    const fetchStoreDetail = () => {
        apiService.getStoreDetails(storeId).then(response => {
            if(mountRef.current){
                props.setStoreData(response);
                const bodyStyles = document.body.style;
                bodyStyles.setProperty('--theme-text-color', response.primaryTextColor || '#fff');
                bodyStyles.setProperty('--theme-background-color', response.primaryColor || '#000');
            }
        }).catch(err => {
            props.setErrorMessage(err?.message || "");
            navigate('/404', { replace: true })
        })
    }

    const fetchCurrentUser = () => {
        apiService.getCurrentUserDetails()
        .then(user => {
            if(user && (user.terminalId === null || user.terminalId === "")){
                props.setErrorMessage("Kiosk cannot be used because no payment terminal has been assigned");
                dispatch(setUser(null));
                dispatch(emptyCart());
                jwtService.logout();
                navigate(`/store/${storeId}`);
            }else{
                dispatch(setUser(user));
            }
        }).catch(err => {})
    }

    if(props.children) return props.children;
    return <Outlet />
}

const mapStateToProps = (state) => {
    return {
        storeUser: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      setStoreData: (outlet) => {
        dispatch(setStoreData(outlet));
      },
        setErrorMessage: (message) => dispatch(setErrorMessage(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentWrapper);
