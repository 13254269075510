import { Formik, Form } from "formik";
import { useEffect } from "react";
import * as yup from 'yup';
import { connect } from "react-redux";
import CustomButton from "../custom-components/custom-button";
import CustomCheckbox from "../custom-components/custom-checkbox";

import { ServiceTypeInfo } from "./ServiceTypeInfo";
import { YourDetailsForm } from "./YourDetailsForm";
import { OrderSummary } from "./OrderSummary";
import { updateCart } from "../../store/actions/ProductActions";

const defaultValidationSchema = {
    note: yup.string(),
    name: yup.string().trim().required().label("Name"),
    lastName: yup.string(),
    email: yup.string().trim().email().label("Email"),
}

const CheckoutForm = ({ formData, handleSubmit, basket, storeData, serviceType, loading, marketing, setMarketing, updateCart }) => {
    const handleChangeField = (props, name, value) => {
        updateCart({...basket, counterDineType: value, userDetails: { deliveryType: value}})
        props.setFieldValue(name, value)
        props.setFieldTouched(name, value)
    }
    const checkoutUserInfo = formData && Object.keys(formData).length ? formData : {
        name: "",
        lastName: "",
        email: "",
        note: "",
        deliveryType: 'EAT_IN'
    };

    useEffect(() => {
        if(checkoutUserInfo && checkoutUserInfo.marketing !== undefined){
            setMarketing(checkoutUserInfo.marketing)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[checkoutUserInfo])

    return <Formik
            initialValues={checkoutUserInfo}
            validateOnMount
            enableReinitialize={true}
            validationSchema={yup.object(defaultValidationSchema)}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <Form noValidate>
                    <div className="checkout-order-list">
                        <div className="box-container order-container">
                            <div className="order-summary-section">
                                <ServiceTypeInfo
                                    props={props}
                                    handleChangeField={handleChangeField}
                                />
                                <YourDetailsForm
                                    props={props}
                                    handleChangeField={handleChangeField}
                                />
                                <OrderSummary 
                                    basket={basket} 
                                    serviceType={serviceType}
                                    currency={storeData?.currency || "gbp"}
                                />
                            </div>
                        </div>
                        <div className="box-container" style={{ marginTop: "30px" }}>
                            <div className="separator separator-theme"></div>
                        </div>
                        <div className="sticky-footer checkout-wrapper-footer">
                            <div className="box-container footer">
                                <div className="d-flex align-items-center accept-subscription">
                                    <CustomCheckbox 
                                        containerClass={""} 
                                        label={""} 
                                        isChecked={marketing} 
                                        handleClick={() => {
                                            // setIsChecked(!isChecked);
                                            setMarketing(!marketing);
                                        }} 
                                    />
                                    <label htmlFor="accept-subscription"><small>I do not want to receive</small> <small className="text-secondary">updates about promotions, discounts and special offers from {storeData?.displayName}</small></label>
                                </div>
                                <CustomButton className="btn-block" disabled={!(props.isValid) || loading} type="submit" loader={loading}> {basket?.totalAmount > 0 ? "Proceed to Pay" : "Place Order"}</CustomButton>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateCart: (payload) => dispatch(updateCart(payload)),
    };
};

const mapStateToProps = (state) => {
    return {
        storeData: state.storeData,
        basket: state.cart,
        serviceType: 'kiosk',

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);