import { useNavigate } from "react-router-dom";
import "../../assets/styles/error.scss";
import CustomButton from "../custom-components/custom-button";

export function ErrorFallback({ error }){
    const navigate = useNavigate();
    return (
        <div className="error-container" role="alert">
            <p className="mb-0">Something went wrong:</p>
            <p>{error.message}</p>
            <CustomButton
                className="error-button-container"
                onClick={() => navigate(-1)}
            >
                Click here to go back
            </CustomButton>
        </div>
    )
}