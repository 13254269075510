import SingleOrderSummaryInfo from "./SingleOrderSummaryInfo";

export const OrderSummary = ({ basket, serviceType, currency}) => {
    return <>
        <div className="separator separator-theme mt-5"></div>
        <div className="checkout-order-summary-title">Order Summary</div>
        <SingleOrderSummaryInfo
            currency={currency}
            basket={basket}
            serviceType={serviceType}
        />
    </>
}