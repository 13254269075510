import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Layouts({ children }) {
    const { pathname } = useLocation();

    useEffect(() => {
        document.getElementsByClassName('layout')[0]?.scrollTo(0, 0);
        document.getElementsByTagName('body')[0]?.scrollTo(0, 0)
    }, [pathname]);

    useEffect(() => {
        const resizeHeight = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            const metaViewport = document.querySelector('meta[name=viewport]')
            metaViewport.setAttribute('content', 'viewport-fit=cover, user-scalable=no, width=device-width, initial-scale=1, maximum-scale=1, virtual-keyboard=overlays-content')
        }
        window.addEventListener('resize', () => {
            // We execute the same script as before
            resizeHeight();
        });
        resizeHeight();
        return () => {
            window.removeEventListener('resize', () => { })
        }
    })
    return <div className="layout">{children}</div>;
}

export default Layouts;