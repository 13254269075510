import { Route, Routes } from "react-router-dom"
import Cart from "./components/Cart";
import ComponentWrapper from "./components/component-wrapper";
import NotFoundComponent from "./components/Error-Component/NotFoundComponent";
import UserLogin from "./components/Login"
import Menus from "./components/Menus";
import Checkout from "./components/Checkout";
import constants from "./constants/constants";
import OrderDetail from "./components/Order-Detail";


const BaseRouter = () => {
    return (
        <Routes>
            <Route path={`${constants.ROUTES.OUTLET}`} element={<ComponentWrapper />}>
                <Route path="" element={<UserLogin />} />
                <Route path={`${constants.ROUTES.MENU}`} element={<Menus />} />
                <Route path={`${constants.ROUTES.BASKET}`} element={<Cart />} />
                <Route path={`${constants.ROUTES.CHECKOUT}`} element={<Checkout />} />
                <Route path={`${constants.ROUTES.ORDERS}`} element={<OrderDetail />} />
            </Route>
            <Route path="*" element={<NotFoundComponent />} />
        </Routes>
    )
}

export default BaseRouter;
