import React from "react";
import CustomButton from "../../custom-components/custom-button";
import CustomCheckbox from "../../custom-components/custom-checkbox";
import "../menu-item-list.scss";

const ItemFilterModal = ({
    handleFilterChange,
    dynamicCustomAttributes,
    setDynamicCustomAttributes,
    setAttributeValueIds,
    attributeValueIds,
}) => {

    const handleOnChangeCustomAttributes = (attributePosition, valuePosition) => {

        const updatedDynamicCustomAttributes = dynamicCustomAttributes?.map((attribute, index) => {
            if(index === attributePosition){
                return {
                    ...attribute,
                    checkedValues: attribute.checkedValues.map((value, valIndex) => {
                        return valIndex === valuePosition ? !value : value
                    })
                }
            }
            return attribute;
        })
        setDynamicCustomAttributes(updatedDynamicCustomAttributes)
    }

    return (
        <div className="filter-modal">
            <div className="filter-body box-container">
                {dynamicCustomAttributes?.map((attribute:any, index: any) => {
                    return(
                        <React.Fragment key={index}>
                            <div className="filter-title">Select {(attribute?.attributeName?.charAt(0).toUpperCase() + attribute?.attributeName?.slice(1)) || ""}</div>
                            <div className="checkbox-ml-1">
                                {attribute?.attributeValues?.map((opt, id) => (
                                    <CustomCheckbox
                                        key={id}
                                        label={opt}
                                        isChecked={attribute.checkedValues[id]}
                                        handleClick={() => handleOnChangeCustomAttributes(index, id)}
                                    />
                                ))}
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
            <div className="bottom-fixed">
                <div className="separator separator-theme"></div>
                <div className="filter-bottom-button-container">
                    <div className="box-container filter-bottom-button">
                        <CustomButton
                            className="btn-block"
                            onClick={handleFilterChange}>
                            Confirm
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemFilterModal;