import CustomCheckbox from "../custom-components/custom-checkbox";

export const ServiceTypeInfo = ({props, handleChangeField}) => {
    return <div className="service-type-info-section">
        <div className="checkout-page-sub-title">Pick Up - <span>As soon as possible</span></div>
        <div className="sub-section">
            <div className="delivery-type-container">
                <CustomCheckbox containerClass={""} type="circle" label={"Eat-In"} isChecked={props.values.deliveryType === 'EAT_IN'} handleClick={() => handleChangeField(props, 'deliveryType', 'EAT_IN')} />
                <CustomCheckbox containerClass={""} type="circle" label={"Takeaway"} isChecked={props.values.deliveryType === 'TAKE_AWAY'} handleClick={() => handleChangeField(props, 'deliveryType', 'TAKE_AWAY')} />
            </div>
        </div>
    </div>
}