const constants = {
    ROUTES:{
        OUTLET: "/store/:storeId",
        MENU: "menus",
        BASKET: "basket",
        CHECKOUT: "checkout",
        ORDERS: "order-detail/:orderId",
    }
}

export default constants;