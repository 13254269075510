import { updateObject } from "../actions/utility";

export const storeInitialState = {
    storeData: {},
    errorMessage: "",
};

export const setStoreData = (state, action) => {
    sessionStorage.setItem("storeData", JSON.stringify(action.payload));
    return updateObject(state, {
        storeData: action.payload,
    })
}

export const setErrorMessage = (state, action) => {
    return updateObject(state, {
        errorMessage: action.payload,
    })
}