/* eslint-disable react/style-prop-object */
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { emptyCart } from "../../store/actions/ProductActions";
import CustomButton from "../custom-components/custom-button";
import CartList from "./Cart-List";
import "./Cart.scss";

const Cart = ({ storeData, basket, ...props }) => {

    const navigate = useNavigate();
    const { storeId } = useParams();

    const allowCheckout = () => {
        if (props.storeFees && props.storeFees.absoluteMinOrder) {
            return basket.subTotal < props.storeFees.absoluteMinOrder;
        } else {
            return !Boolean(basket.subTotal);
        }
    }

    const handleCheckout = () => {
        navigate(`/store/${storeId}/checkout`)
    }

    return (
        <div className="cart-page">
            <div className="box-container custom-header-wrapper header">
                <div className="header-left">
                    <AiOutlineArrowLeft size={45} onClick={() => navigate(-1)} />
                    <div className="header-title">
                        <div className="fs-heading">Your Basket</div>
                    </div>
                </div>
                <button
                    type="button"
                    className={`rounded-button-delete-cart`}
                    onClick={() => props.removeCart()}
                >
                    <span>Delete Cart</span><BiTrash size={40} />
                </button>
            </div>
            <div className="separator separator-theme"></div>
            {(!basket || Object.keys(basket).length === 0) ?
                <div className={"cart-small-list"}>
                    <h2 className="m-0 display-vertical-center"> Oops! No items in cart</h2>
                </div>
                : <>
                    <div className={"cart-list-long"}>
                        <CartList />
                    </div>
                    <div className="sticky-footer cart-wrapper-footer">
                        <div className="separator separator-theme"></div>
                        <div className="box-container footer">
                            <>
                                <div className="cart-total-section">
                                    <span>Subtotal</span>
                                    <span>
                                        <FormattedNumber
                                            style="currency"
                                            value={basket.subTotal || 0}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            currency={storeData.currency || "gbp"}
                                        />
                                    </span>
                                </div>
                                <CustomButton disabled={allowCheckout()} onClick={handleCheckout} className="btn-block">Go to Checkout</CustomButton>
                            </>
                        </div>
                    </div>
                </>}

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        storeData: state.storeData,
        basket: state.cart,
        storeFees: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeCart: () => dispatch(emptyCart()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);

