import './custom-input.scss';

export const CustomInput = ({
    onChange,
    value,
    className,
    label,
    name,
    labelClassName,
    type = "text",
    placeHolder = "",
    disabled = false,
}) => {
    return (
        <>
            {label && <label labelFor={name} className={labelClassName}>{label}</label>}
            <input type={type} placeholder={placeHolder} name={name} onChange={(event) => onChange(event.target.value)} value={value} className={`${className ?? ''}`} disabled={disabled} autoComplete="off"/>
        </>
    )
}