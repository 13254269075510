
import { useRef, useState } from 'react';
import './custom-dropdown.scss';
import { AiOutlineDown } from "react-icons/ai";

export const CustomDropdown = ({
    options,
    onSelect,
    value,
    dropDownHeaderClass,
    dropDownContainerClass,
    dropDownListContainerClass,
    dropDownListClass,
    dropDownItemClass,
    label,
    name,
    labelClassName,
    noOptionLabel,
    placeHolder,
    disabled // TODO: implementation
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();
    
    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = option => () => {
        onSelect(option);
        setIsOpen(false);
    };

    const closeOpenMenus = (e)=>{
        if(ref.current && isOpen && !ref.current.contains(e.target)){
          setIsOpen(false)
        }
    }

    document.addEventListener('mousedown',closeOpenMenus);
    let actualValue = value || placeHolder || "";
    if(parseInt(value) === 0) {
        actualValue = value.toString();
    }
    return (
        <div className={dropDownContainerClass ? dropDownContainerClass : 'drop-down-container'} ref={ref}>
            <div className={dropDownHeaderClass ? dropDownHeaderClass : `drop-down-header`} onClick={toggling}>
                <span>{ actualValue || placeHolder || ""}</span>
                <AiOutlineDown size={10} />
            </div>
            {isOpen && (
                <div className={dropDownListContainerClass ? dropDownListContainerClass : 'drop-down-list-container'}>
                    <div className={dropDownListClass ? dropDownListClass : 'drop-down-list'}>
                        {options.length ? options.map(option => (
                            <div className={dropDownItemClass ? dropDownItemClass : 'list-item'} onClick={onOptionClicked(option)} key={Math.random()}>
                                {option.label}
                            </div>
                        )) : <div className={dropDownItemClass ? dropDownItemClass : 'list-item'} onClick={() => setIsOpen(false)}>
                           {noOptionLabel ? noOptionLabel : 'No Options'}
                        </div>}
                    </div>
                </div>
            )}
        </div>
    )
}