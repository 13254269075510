/* eslint-disable react/style-prop-object */
import React, { Fragment, useEffect, useState } from "react";
import { AiOutlineInfoCircle, AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { observerShowDescription } from "../../../common/utils";
import { addItemsToCartV1, updateItemFromCart } from "../../../store/actions/ProductActions";
import CustomButton from "../../custom-components/custom-button";
import CustomCheckbox from "../../custom-components/custom-checkbox";
import CustomModal from "../../custom-components/custom-modal";
import CustomAttributesModal from "../Custom-Attributes-Modal";
import "../menu-item-list.scss";

const ItemBanner = (props) => (
    <div className="item-banner box-container">
        <div className="item-banner-heading">
            {props.heading}
        </div>
        <div className="item-banner-sub-heading">
            &nbsp;- {props.subHeading}
        </div>
    </div>
);

const ModifierWithoutPrice = ({ modifier, backgroundColor, isLast, handleSelection, handleMinusClick, handlePlusClick, type = "box", groupMaxSelection }) => (
    <div className="box-container item-row free-modifier">
        <div className="checkbox-price">
            <div>
                <CustomCheckbox label={modifier.displayName} isChecked={modifier.isSelected} handleClick={handleSelection} type={type} />
                <div className={`calories ${modifier.isSelected ? "selected" : ""}`} style={{ height: "20px" }}>
                    {modifier.kcal ? modifier.kcal + " Kcal" : ""}
                </div>
            </div>
            {!(groupMaxSelection === 1 && modifier.maximumQty === 1) && modifier.isSelected && <div style={{ display: "flex", alignItems: "center" }}>
                {Boolean(modifier.maximumQty) && <span style={{ fontSize: "1.7rem", paddingRight: "10px" }}>Max. Qty {modifier.maximumQty}</span>}
                {Boolean(modifier.quantity) && <AiOutlineMinusSquare color={backgroundColor} size={50} onClick={handleMinusClick} />}
                {Boolean(modifier.quantity) && <span className="modifier-option-quantity">{modifier.quantity}</span>}
                {
                    (!Boolean(modifier.maximumQty) || (modifier.quantity < modifier.maximumQty)) ?
                        <AiOutlinePlusSquare color={backgroundColor} size={50} onClick={handlePlusClick} />
                        : null}
            </div>}
        </div>
    </div>
)

const ModifierWithPrice = ({ modifier, backgroundColor, isLast, handleSelection, handleMinusClick, handlePlusClick, currency, type = "box", groupMaxSelection }) => (
    <div className="box-container item-row">
        <div className="checkbox-price">
            <CustomCheckbox label={modifier.displayName} isChecked={modifier.isSelected} handleClick={handleSelection} type={type} />
            { <span>+<FormattedNumber
                style="currency"
                value={modifier.price}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
                currency={currency || "gbp"}
            />{" each"}</span>}
        </div>
        {Boolean(modifier.isSelected || modifier.kcal) && <div className="modifier-footer">
            <div className="calories" style={{ height: "20px" }}>
                {modifier.kcal ? modifier.kcal + " Kcal" : ""}
            </div>
            {!(groupMaxSelection === 1 && modifier.maximumQty === 1) && modifier.isSelected && <div style={{ display: "flex", alignItems: "center" }}>
                {Boolean(modifier.maximumQty) && <span style={{ fontSize: "1.7rem", paddingRight: "10px" }}>Max. Qty {modifier.maximumQty}</span>}
                <AiOutlineMinusSquare color={backgroundColor} size={50} onClick={handleMinusClick} />
                <span className="modifier-option-quantity">{modifier.quantity}</span>
                {(!Boolean(modifier.maximumQty) || (modifier.quantity < modifier.maximumQty)) ? <AiOutlinePlusSquare color={backgroundColor} size={50} onClick={handlePlusClick}/> : null }
            </div>}
        </div>}
    </div>
)

const getSubHeading = (modifierGroup) => {
    let subHeading = modifierGroup.isRequired ? " Required - " : "";
    if (modifierGroup.selectionType === "Multiple" || modifierGroup.selectionType === "Single") {
        subHeading += "Choose up to ";
        subHeading += modifierGroup.maximumSelect ? modifierGroup.maximumSelect : 1
    }
    else {
        subHeading += "Unlimited Selection";
    }
    return subHeading;
}

const getMaxSelection = (modifierGroup) => {
    if (modifierGroup.selectionType === "Unlimited") {
        return 999;
    } else {
        return modifierGroup.maximumSelect ? modifierGroup.maximumSelect : 1;
    }
}

const ItemModifierModal = ({ itemData, storeData, cartLoading, enableModifiers, ...props }) => {

    const [specialInstructions, setSpecialInstructions] = useState(itemData.specialInstructions || '');
    const [visible, setVisible] = useState(false);
    const [itemDetails, setItemDetails] = useState(itemData);
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState(false);
    const [showCustomAttributeModal, setShowCustomAttributeModal] = useState(false);

    const bodyStyles = document.body.style;
    const backgroundColor = bodyStyles.getPropertyValue('--theme-background-color');

    useEffect(() => {
        if (enableModifiers) {
            let newErrors = {};
            if (itemData.variants.length) {
                newErrors = { "variant": "Please select a variant" };
            }
            itemData.assignedModifiers.forEach((group) => {
                if (group.isRequired) {
                    newErrors[group._id] = "Please select atleast one " + group.displayName;
                }
            })
            setErrors(newErrors);
            calculateSubTotal({
                ...itemData,
                quantity: itemData.quantity ? itemData.quantity : 1,
            });
        }
    }, [itemData, enableModifiers])

    useEffect(() => {
        observerShowDescription();
    }, [])

    const calculateSubTotal = (item) => {
        let price = item.itemPrice;
        item.assignedModifiers.forEach((group) => {
            price += group.modifierSubTotal ? group.modifierSubTotal : 0;
        });
        groupModifiers(item);
        setItemDetails({ ...item, price, subTotal: price * item.quantity })
    }

    const groupModifiers = (item) => {
        item.modifiers = [];
        for (let i = 0; i < item.assignedModifiers.length; i++) {
            let curr = 0;
            for (
                let j = 0;
                j < item.assignedModifiers[i].modifiers.length;
                j++
            ) {
                if (item.assignedModifiers[i].modifiers[j].isSelected) {
                    item.modifiers.push({
                        ...item.assignedModifiers[i].modifiers[j],
                        price:
                            item.assignedModifiers[i].feeType === "Free"
                                ? 0
                                : item.assignedModifiers[i].modifiers[j].price,
                        modifierGroup: {
                            modifierGroupRef:
                                item.assignedModifiers[i].modifierGroupId,
                            displayName: item.assignedModifiers[i].displayName,
                            selectionType:
                                item.assignedModifiers[i].selectionType || "",
                            maximumSelect:
                                item.assignedModifiers[i].maximumSelect || "",
                            feeType: item.assignedModifiers[i].feeType || "",
                        },
                    });
                    curr += 1;
                }
                if (curr === item.assignedModifiers[i].currentSelection) {
                    break;
                }
            }
        }
    }

    const handleVariableSelection = (variantIndex) => {
        let variantPrice = itemDetails.itemPrice;
        calculateSubTotal({
            ...itemDetails, 
            variants: itemDetails?.variants.map((variant, ind) => {
                if (ind === variantIndex) {
                    variantPrice = variant.variantSellingPrice;
                    return { ...variant, isSelected: true };
                }
                else {
                    return { ...variant, isSelected: false };
                }
            }), 
            itemPrice: variantPrice,
        });
        if (errors["variant"]) {
            let newErrors = { ...errors };
            delete newErrors["variant"];
            setErrors(newErrors);
        }
    }

    const handleSingleSelection = (modifierIndex, groupIndex) => {
        let newGroup = itemDetails.assignedModifiers[groupIndex];

        newGroup.currentSelection = 1;

        newGroup.modifiers = newGroup.modifiers.map((mod, index) => {
            if (index === modifierIndex) {
                if (newGroup.feeType === "Paid") {
                    newGroup.modifierSubTotal = newGroup.modifiers[modifierIndex].price;
                }
                if (errors[newGroup._id]) {
                    let newErrors = { ...errors };
                    delete newErrors[newGroup._id];
                    setErrors(newErrors);
                }
                return {
                    ...mod,
                    isSelected: true,
                    quantity: 1,
                }
            }
            else {
                return {
                    ...mod,
                    isSelected: false,
                    quantity: 0,
                }
            }
        })
        calculateSubTotal({
            ...itemDetails,
            assignedModifiers: itemDetails.assignedModifiers.map((mod, ind) => {
                if (ind === groupIndex) {
                    return newGroup;
                }
                else {
                    return mod;
                }
            })
        });
    }

    const handleSingleUnrequiredSelection = (modifierIndex, groupIndex) => {
        let newGroup = itemDetails.assignedModifiers[groupIndex];

        if (newGroup.modifiers[modifierIndex].isSelected === true) {
            newGroup.modifiers[modifierIndex].isSelected = false;
            if (newGroup.feeType === "Paid") {
                newGroup.modifierSubTotal -= newGroup.modifiers[modifierIndex].price * newGroup.modifiers[modifierIndex].quantity;
            }
            newGroup.currentSelection = 0;
        }
        else {
            if (newGroup.currentSelection === 1) {
                newGroup.modifiers = newGroup.modifiers.map((mod, index) => {
                    if (index === modifierIndex) {
                        if (newGroup.feeType === "Paid") {
                            newGroup.modifierSubTotal = newGroup.modifiers[modifierIndex].price;
                        }
                        return {
                            ...mod,
                            isSelected: true,
                            quantity: 1,
                        }
                    }
                    else {
                        return {
                            ...mod,
                            isSelected: false,
                            quantity: 0,
                        }
                    }
                })
            }
            else {
                newGroup.modifiers[modifierIndex].isSelected = true;
                newGroup.modifiers[modifierIndex].quantity = 1;
                if (newGroup.feeType === "Paid") {
                    newGroup.modifierSubTotal = newGroup.modifiers[modifierIndex].price;
                }
                newGroup.currentSelection = 1;
            }
        }
        calculateSubTotal({
            ...itemDetails,
            assignedModifiers: itemDetails.assignedModifiers.map((mod, ind) => {
                if (ind === groupIndex) {
                    return newGroup;
                }
                else {
                    return mod;
                }
            })
        });
    }

    const handleMultipleSelection = (modifierIndex, groupIndex, maxSelection) => {
        let newGroup = itemDetails.assignedModifiers[groupIndex];
        if (maxSelection === 1) {
            handleSingleUnrequiredSelection(modifierIndex, groupIndex);
            return;
        }

        if (newGroup.modifiers[modifierIndex].isSelected === true) {
            newGroup.modifiers[modifierIndex].isSelected = false;
            if (newGroup.feeType === "Paid") {
                // newGroup.modifierSubTotal -= newGroup.modifiers[modifierIndex].price * newGroup.modifiers[modifierIndex].quantity;
                newGroup.modifierSubTotal -= newGroup.modifiers[modifierIndex].price;
            }
            newGroup.currentSelection -= 1;
            if (newGroup.isRequired && newGroup.currentSelection === 0) {
                let newErrors = { ...errors };
                newErrors[newGroup._id] = "Please select atleast one " + newGroup.displayName;
                setErrors(newErrors);
            }
        }
        else {
            if (newGroup.currentSelection === maxSelection) {
                return;
            }
            else {
                newGroup.modifiers[modifierIndex].isSelected = true;
                newGroup.modifiers[modifierIndex].quantity = 1;
                if (newGroup.feeType === "Paid") {
                    if (newGroup.modifierSubTotal) {
                        newGroup.modifierSubTotal += newGroup.modifiers[modifierIndex].price;
                    } else {
                        newGroup.modifierSubTotal = newGroup.modifiers[modifierIndex].price;
                    }
                }
                newGroup.currentSelection = newGroup.currentSelection ? newGroup.currentSelection + 1 : 1;
                if (errors[newGroup._id]) {
                    let newErrors = { ...errors };
                    delete newErrors[newGroup._id];
                    setErrors(newErrors);
                }
            }
        }
        calculateSubTotal({
            ...itemDetails,
            assignedModifiers: itemDetails.assignedModifiers.map((mod, ind) => {
                if (ind === groupIndex) {
                    return newGroup;
                }
                else {
                    return mod;
                }
            })
        });
    }

    const handleModMinusClick = (modifierIndex, groupIndex) => {
        calculateSubTotal({
            ...itemDetails, assignedModifiers: itemDetails.assignedModifiers.map((mod, ind) => {
                if (ind === groupIndex) {
                    const modifierSubTotal = mod.feeType === "Paid" && mod.modifiers[modifierIndex].quantity > 1 ? mod.modifiers[modifierIndex].price : 0;
                    return {
                        ...mod, modifiers: mod.modifiers.map((item, index) => {
                            if (index === modifierIndex) {
                                return { ...item, quantity: item.quantity > 1 ? item.quantity - 1 : 1 }
                            }
                            else {
                                return item;
                            }
                        }),
                        modifierSubTotal: mod.modifierSubTotal -= modifierSubTotal,
                    };
                }
                else {
                    return mod;
                }
            })
        })
    }

    const handleModPlusClick = (modifierIndex, groupIndex, maxSelection) => {
        calculateSubTotal({
            ...itemDetails, assignedModifiers: itemDetails.assignedModifiers.map((mod, ind) => {
                if (ind === groupIndex) {
                    const modifierSubTotal = mod.feeType === "Paid" && (mod.modifiers[modifierIndex].quantity === 0 || mod.modifiers[modifierIndex].quantity < maxSelection)
                        ? mod.modifiers[modifierIndex].price
                        : 0;
                    return {
                        ...mod, modifiers: mod.modifiers.map((item, index) => {
                            if (index === modifierIndex) {
                                return { ...item, quantity: item.quantity ? item.quantity < maxSelection ? item.quantity + 1 : item.quantity : 1 }
                            }
                            else {
                                return item
                            }
                        }),
                        modifierSubTotal: mod.modifierSubTotal += modifierSubTotal,
                    };
                }
                else {
                    return mod;
                }
            })
        });
    }

    const handleAddBasket = () => {
        if (Object.keys(errors).length > 0) {
            setShowErrors(true);
        } else if(cartLoading !== true) {
            if (props.isEdit) {
                props.updateItemFromCart(props.itemIndex, { ...itemDetails, specialInstructions: specialInstructions })
                props.closeModal()
            } else {
                props.addItemsToCart({ ...itemDetails, specialInstructions: specialInstructions })
                props.closeModal()
            }
        }
    }

    return (
        <div className="modifier-container">
            <div className="modifier-header-extended">
                <div className="box-container modifier-quantity-price">
                    <div className="modifier-quantity">
                        <span className="quantity-span">Quantity</span>
                        <AiOutlineMinusSquare color={backgroundColor} size={65} onClick={() => { itemDetails.quantity > 1 && setItemDetails({ ...itemDetails, quantity: itemDetails.quantity - 1, subTotal: itemDetails.price * (itemDetails.quantity - 1) }) }} />
                        <span className="modifier-option-quantity">{itemDetails.quantity}</span>
                        <AiOutlinePlusSquare color={backgroundColor} size={65} onClick={() => { 
                            setItemDetails({ ...itemDetails, quantity: itemDetails.quantity + 1, subTotal: itemDetails.price * (itemDetails.quantity + 1) }) 
                        }} />
                    </div>
                    <div className="item-modifier-selling-price">
                        <FormattedNumber
                            style="currency"
                            value={itemDetails.itemPrice}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={storeData.currency || "gbp"}
                        />
                        <p className="text-secondary m-0">Price per item</p>
                    </div>
                </div>
                <div className="modifier-separator"></div>
            </div>
            <div className="modifier-list-container">
                {itemDetails && itemDetails.description &&
                    <div className="box-container modifier-description">
                        <p className={`m-0 ${visible ? 'remove-line-camp' : ""}`}>
                            <pre>
                                {itemDetails?.description}
                            </pre>
                        </p>
                        <label className={`${visible ? "show-view-more" : ""}`} onClick={() => setVisible(!visible)}>{visible ? "View Less" : "View More"}</label>
                    </div>
                }
                {((itemDetails.customAttributes && itemDetails.customAttributes.length > 0) || 
                    (itemDetails.nutritions &&
                        Object.values(itemDetails.nutritions).some(
                            (val) => val !== 0,
                        ))) && 
                    <div className={`box-container ${"modifier-allergens-section"}`}>
                        <span className="item-tag item-details-link" onClick={() => setShowCustomAttributeModal(true)}>
                            <AiOutlineInfoCircle /> Click for Item details
                        </span> 
                    </div>}
                {Boolean(itemDetails.variants.length) && <>
                    <ItemBanner heading="Select Variant" subHeading="Required" />
                    <div className="box-container variant-wrapper">
                        {
                            (itemDetails?.variants).map((variant, index) => {
                                return (<div key={index} className="item-row">
                                    <div className="checkbox-price">
                                        <CustomCheckbox label={variant.variantName} isChecked={variant.isSelected} handleClick={() => handleVariableSelection(index)} type="circle" />
                                        <FormattedNumber
                                            style="currency"
                                            value={variant.variantSellingPrice}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            currency={storeData?.currency || "gbp"}
                                        />
                                    </div>
                                    <div className="calories"></div>
                                </div>)
                            })
                        }
                    </div>
                </>}
                {Boolean(itemDetails?.assignedModifiers?.length) &&
                    itemDetails.assignedModifiers.map((modifierGroup, index) => {
                        const groupIndex = index;
                        return (<Fragment key={index}>
                            <ItemBanner heading={"Choose " + modifierGroup.displayName} subHeading={getSubHeading(modifierGroup)} />
                            <div className="variant-wrapper">
                                {modifierGroup.modifiers.map((opt, index) => {
                                    if (modifierGroup.feeType === "Free") {
                                        return <ModifierWithoutPrice
                                            key={index}
                                            modifier={opt}
                                            backgroundColor={backgroundColor}
                                            type={modifierGroup.isRequired && getMaxSelection(modifierGroup) === 1 ? "circle" : "box"}
                                            isLast={index === modifierGroup.modifiers.length - 1}
                                            handleSelection={() => {
                                                modifierGroup.isRequired && getMaxSelection(modifierGroup) === 1 ? 
                                                handleSingleSelection(index, groupIndex) : 
                                                handleMultipleSelection(index, groupIndex, getMaxSelection(modifierGroup))
                                            }}
                                            handleMinusClick={() => handleModMinusClick(index, groupIndex)}
                                            handlePlusClick={() => handleModPlusClick(index, groupIndex, opt.maximumQty ? opt.maximumQty : 999)}
                                            groupMaxSelection={getMaxSelection(modifierGroup)}
                                        />;
                                    } else {
                                        return <ModifierWithPrice
                                            key={index}
                                            modifier={opt}
                                            currency={storeData.currency || "gbp"}
                                            backgroundColor={backgroundColor}
                                            type={modifierGroup.isRequired && getMaxSelection(modifierGroup) === 1 ? "circle" : "box"}
                                            isLast={index === modifierGroup.modifiers.length - 1}
                                            handleSelection={() => {
                                                modifierGroup.isRequired && getMaxSelection(modifierGroup) === 1
                                                    ? handleSingleSelection(index, groupIndex)
                                                    : handleMultipleSelection(index, groupIndex, getMaxSelection(modifierGroup))
                                            }}
                                            handleMinusClick={() =>  handleModMinusClick(index, groupIndex)}
                                            handlePlusClick={() => handleModPlusClick(index, groupIndex, opt.maximumQty ? opt.maximumQty : 999)}
                                            groupMaxSelection={getMaxSelection(modifierGroup)}
                                        />;
                                    }
                                }
                                )}
                            </div>
                        </Fragment>)
                    })
                }
                <div className="add-note-container box-container">
                    {(Boolean(itemDetails?.assignedModifiers?.length) || Boolean(itemDetails.variants.length) || (itemDetails && itemDetails.description)) ? <div className="modifier-separator"></div> : null}
                    <div style={{ fontSize: "1.9rem", fontFamily: "Europa Bold", paddingTop: "30px", marginBottom: "10px" }}>Add Note</div>
                    <div className="">
                        <input className="item-add-note" placeholder="Insert Note" value={specialInstructions} onChange={(e) => setSpecialInstructions(e.target.value)} />
                    </div>
                </div>
                <div className="box-container">
                    <div className="separator separator-theme"></div>
                </div>
                <div className="sticky-footer modifier-bottom-fixed">
                    <div className="basket-button-container">
                        <div className="box-container bottom-button">
                            <CustomButton className="btn-block" onClick={handleAddBasket} loader={false} disabled={false} >
                                <div className="add-to-basket-button">
                                    <span>Add to Basket</span>
                                    <FormattedNumber
                                        style="currency"
                                        value={itemDetails.subTotal || 0}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        currency={storeData.currency || "gbp"}
                                    />
                                </div>
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </div>
            <CustomModal
                isModalOpen={showErrors && Boolean(Object.keys(errors).length)}
                onRequestClose={() => setShowErrors(false)}
                ariaHideApp={false}
                hideDivider={true}
                modalContainerClass={'modifier-validation-modal-container'}
                isClosable={true}
            >
                <div className="box-container">
                    <ul className="modifier-validation-modal-list">
                        {
                            Object.keys(errors).map((key) =>
                                <li key={key} className="modifier-validation-modal-item"> {errors[key]} </li>)
                        }
                    </ul>
                    <div className="error-pop-up-button">
                        <CustomButton className="btn-block" onClick={() => setShowErrors(false)}>
                            <span>Okay</span>
                        </CustomButton>
                    </div>
                </div>
            </CustomModal>
            <CustomAttributesModal
                showCustomAttributeModal={showCustomAttributeModal}
                setShowCustomAttributeModal={setShowCustomAttributeModal}
                itemData={itemDetails}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        storeData: state.storeData,
        cartLoading: state.cartLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addItemsToCart: (item) => dispatch(addItemsToCartV1(item)),
        updateItemFromCart: (index, item) => dispatch(updateItemFromCart(index, item)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemModifierModal);
