export const ErrorMsg = {
    "EMPTY": "This field is required",
    "IS_EMPTY": "This field is required",
    "INVALID_VALUE": "This field value is not valid",
    "NOT_FOUND": "Item not found",
    "USER_ALREADY_EXISTS": "User already exists",
    "VENDOR_NOT_CREATED": "Error in Vendor creation",
    "FETCH_SUCCESS": "Data Retrive successfully.",
    "NOT_UPDATED": "Error in update",
    "NOT_DELETED": "Error in delete",
    "NOT_ARCHIVED": "Error in archive",
    "NOT_UNARCHIEVD": "Error in un archive",
    "USER_NOT_CREATED": "Error in user create",
    "INVALID_CURRENT_PASSWORD": "Invalid current password",
    "DELETE_SUCCESS": "Delete successfully",
    "INVALID_PLAN_SAVE_REQUEST": "Invalid FloorPlan save request.",
    "NOT_ALLOW_TO_ARCHIVE": "Not allowed to archive.",
    "USER_NOT_UPDATED": "Error in user update.",
    "VENDOR_EMAIL_ALREADY_EXISTS": "Email already associated with another outlet",
    "INVALID_LOGIN": "Invalid login.",
    "USERTOKEN_NOT_CREATED": "User token not created.",
    "ACCESS_DENIED": "You are not allow to access resources.",
    "UNAUTHORIZED_ACCESS": "Unauthorized access. Please verify your email address.",
    "INVALID_LOGIN_CREDENTIALS": "Invalid login credentials.",
    "REFRESH_TOKEN_EXPIRED": "Refresh token expired.",
    "TOKEN_NOT_CREATED": "Auth token not created.",
    "SITE_NOT_CREATED": "Error in Site creation",
    "SITE_ZONE_NOT_CREATED": "Error in Site Zones creation",
    "INVALID_SITE_ZONE_SAVE_REQUEST": "Error in Site Zones save request.",
    "INVALID_SITE_ZONE_UPDATE_REQUEST": "Error in Site Zones update.",
    "INVALID_SITE_UPDATE_REQUEST": "Error in Site update.",
    "INVALID_ASSIGN": "Invalid Assign",
    "SUPPLIER_NOT_CREATED": "Error in supplier create",
    "NOT_ALLOWED_TO_CREATE_WAREHOUSE": "Error in create warehouse",
    "INVALID_GET_SITES_REQUEST": "Invalid get sites request.",
    "INGREDIENT_NOT_CREATED": "Error in Ingredient creation",
    "NOT_ALLOWED_TO_CREATE_INGREDIENT": "Insufficient/invalid params for creating an Ingredient.",
    "NOT_ALLOWED_TO_UPDATE_INGREDIENT": "Insufficient/invalid params for updating an Ingredient.",
    "STOCK_TRANSACTION_NOT_CREATED": "Error in create Ingredient transaction",
    "STOCK_CANNOT_BE_LESS_THAN_ZERO": "Stock value can't be less than current stock units.",
    "NOT_ALLOWED_TO_CREATE_MENU_INVALID_VENDOR_DETAILS": "Invalid Vendor details in creating Menu.",
    "NOT_ALLOWED_TO_CREATE_MENU_INSUFFICIENT_WAREHOUSE_DETAILS": "Insufficient Warehouse details in creating Menu.",
    "NOT_ALLOWED_TO_CREATE_MENU_INVALID_WAREHOUSE_DETAILS": "Invalid Warehouse details in creating Menu.",
    "NOT_ALLOWED_TO_CREATE_MENU_WAREHOUSE_DETAILS_NOT_FOUND": "Warehouse details not found for creating Menu.",
    "MENU_NOT_CREATED": "Error in Menu creation",
    "NOT_ALLOWED_TO_UPDATE_MENU_INVALID_VENDOR_DETAILS": "Invalid Vendor details in updating Menu.",
    "NOT_ALLOWED_TO_UPDATE_MENU_INSUFFICIENT_WAREHOUSE_DETAILS": "Insufficient Warehouse details in updating Menu.",
    "NOT_ALLOWED_TO_UPDATE_MENU_WAREHOUSE_DETAILS_NOT_FOUND": "Warehouse details not found for updating Menu.",
    "NOT_ALLOWED_TO_UPDATE_MENU_INVALID_WAREHOUSE_DETAILS": "Invalid Warehouse details in updating Menu.",
    "NOT_ALLOWED_TO_CREATE_INVALID_VENDOR_DETAILS": "Invalid Vendor details in creating.",
    "MENU_GROUP_NOT_CREATED": "Error in Menu Group creation.",
    "NOT_ALLOWED_TO_UPDATE_INVALID_VENDOR_DETAILS": "Invalid Vendor details in Menu Group update.",
    "NOT_ALLOWED_TO_ARCHIVE_INVALID_VENDOR_DETAILS": "Invalid Vendor details in Menu Group archive.",
    "NOT_ALLOWED_TO_UNARCHIVE_INVALID_VENDOR_DETAILS": "Invalid Vendor details in Menu Group un-archive.",
    "MENU_CATEGORY_NOT_CREATED": "Error in Menu category creation.",
    "NOT_ALLOWED_TO_CREATE_NOT_FOUND_MENU_GROUP_DETAILS": "Menu group details not found for creating Menu category.",
    "NOT_ALLOWED_TO_UPDATE_NOT_FOUND_MENU_GROUP_DETAILS": "Menu group details not found for updating Menu category.",
    "NOT_ALLOWED_TO_UPDATE_MISSING_MENU_GROUP_DETAILS": "Insufficient Menu Group details in updating Menu category.",
    "NOT_ALLOWED_TO_CREATE_MISSING_MENU_GROUP_DETAILS": "Insufficient Menu Group details in creating Menu category.",
    "NOT_ALLOWED_TO_ASSIGN_MENU_ITEM_INVALID_VENDOR_DETAILS": "Invalid Vendor details in Assign Menu items.",
    "NOT_ALLOWED_TO_ASSIGN_MENU_ITEM_INVALID_MENU_DETAILS": "Invalid Menu details in Assign Menu items.",
    "ASSIGN_ITEM_NOT_DONE": "Error in Assign Menu Items.",
    "NO_DATA_FOR_ASSIGN_ITEM": "No item is available for assignment.",
    "INVALID_ASSIGN_ITEM_GET_REQUEST": "Invalid Assign Menu Items get request.",
    "NOT_ALLOWED_TO_MODIFY": "Not allowed to perform action because the Menu type is Category.",
    "NOT_ALLOWED_TO_TAKE_ARCHIVE_ACTION": "Invalid Vendor details in Archive.",
    "NOT_ALLOWED_TO_TAKE_UNARCHIVE_ACTION": "Invalid Vendor details in Un-Archive.",
    "NOT_ALLOWED_TO_TAKE_ASSIGN_MODIFIER_ACTION": "Invalid Vendor details in Assign Modifier.",
    "NOT_ALLOWED_TO_DUPLICATE_MENU_INVALID_VENDOR_DETAILS": "Invalid Vendor details in duplicate Menu.",
    "SITE_NOT_FOUND": "Site Not Found",
    "SITE_ZONES_NOT_DELETED": "Site Zones Not Deleted",
    "SITE_NOT_DELETED": "Site Not Delete",
    "NOT_REJECTED": "Unable to Reject",
    "ITEM_NOT_CREATED": "Error in Item creation.",
    "ASSIGN_ERROR": "Error in Assign.",
    "NOT_ALLOWED_UNASSIGN": "Error in UnAssign.",
    "INVALID_UNASSIGN": "Invalid unAssign.",
    "NOT_ALLOWED_TO_ASSIGN": "Invalid assign",
    "INVALID_SERVICE_TYPE": "Invalid Service type selection.",
    "ERR_IN_DELETE_CHECKOUT": "Error in delete checkout.",
    "NO_AREA_SELECTED_FOR_PRINTING_ORDER": "No area selected for printing the order",
    "NO_DATA_AVAILABLE_TO_PROCESS": "No data available for process.",
    "ERR_UPDATE_TABLE_STATUS": "Error in update table status.",
    "ERR_IN_TAB_ARCHIVE": "Error in Tab archive process.",
    "ORDER_IS_ALREADY_CREATED": "Order is already created.",
    "ONLY_TAB_TYPE_ALLOW_AS_SAVE_TAB": "Only tab type allows saving as save Tab.",
    "REFUND_AMOUNT_SHOULD_NOT_BE_GREATER_THAN": "Refund amount should not be greater than actual amount.",
    "REFUND_ERROR": "Refund error!",
    "INVALID_REQUEST_PARAMS": "Required parameters missing to create a payment!",
    "TRANSACTION_DETAILS_NOT_FOUND": "Transaction details not found.",
    "TRANSACTION_DETAILS_NOT_FOUND_DOJO": "Transaction details not found for Dojo.",
    "ERR_IN_DOJO_REFUND_INITIATION": "Error in Dojo refund initiation.",
    "ERR_SAVE_REFUND": "Error in save Refund!",
    "REFUND_NOT_FOUND": "Refund not found.",
    "INVALID_REFUND_REQUEST_DETAILS": "Invalid Refund request details.",
    "ERR_IN_DOJO_REFUND_CAPTURE": "Error in Dojo Refund Capture.",
    "YOU_HAVE_SIGNED_UP_SUCCESSFULLY": "You have signed up successfully!.",
    "YOU_HAVE_SIGNED_UP_SUCCESSFULLY_PLEASE_CONFIRM_YOUR_EMAIL": "You have signed up successfully!.",
    "INVALID_VENDOR_DETAILS_STOCK_TRANSFER": "Invalid Vendor details for Item Stock Transfer.",
    "BASIC_CATEGORY_STOCK_ALLOW_TO_TRANSFER_NOT_OTHERS": "Only Basic stock type Items allow transferring.",
    "EMAIL_IS_ALREADY_REGISTERED_WITH_OTHER_VENDOR": "Email is already registered with other Vendor.",
    "NOT_AUTHORIZED_TO_ARCHIVE_DEFAULT_OUTLET": "You are not authorized to Archive default Outlet.",
    "NOT_ALLOWED_TO_DUPLICATE_SITE_INVALID_VENDOR_DETAILS": "Invalid Vendor details in duplicate Site.",
    "TAB_NOT_CREATED": "Error in Tab creation",
    "TAB_IS_EXIST": "The table already exists!",
    "NOT_ALLOW_TO_CREATE_TAB": "Service is not active for now. Kindly active the service first.",
    "INVALID_DATE": "Start Date is greater than End Date",
    "NOT_ALLOWED_TO_DELETE_INGREDIENT_CATEGORY": "NOT ALLOWED TO DELETE INGREDIENT CATEGORY",
    "NOT_ALLOWED_TO_DELETE_ITEM_CATEGORY": "NOT ALLOWED TO DELETE ITEM CATEGORY",
    "UNAUTHORIZE_ACCESS_OF_SITE": "Unauthorize access of Site.",
    "ITEM_CANNOT_UPDATE": "Item can't be modified as it already sent to kitchen.",
    "ITEM_NOT_FOUND": "Item not found.",
    "PLEASE_OPEN_REGISTER_FOR_PAYMENT": "Please open register for the payment.",
    "NEED_TO_CLOCK_IN_FOR_PAYMENT": "You need to clock in for the payment.",
    "PLEASE_OPEN_CASH_POSITION_BEFORE_RECORD_TRANSACTION": "Please open your cash position from cash drawer before recording a cash payment",
    "OTHER_PAYMENT_GATEWAY_IS_PENDING_FOR_INTEGRATION": "This payment gateway is not supported by NOQ...",
    "INVALID_AMOUNT": "Invalid amount",
    "PAYMENT_GATEWAY_DETAILS_NOT_FOUND": "Payment gateway related details not found.",
    "SOMETHING_WENT_WRONG_WHILE_CAPTURING_PAYMENT": "something went wrong while the payment!",
    "REQUIRED_PARAMETERS_MISSING_TO_CREATE_A_PAYMENTITENT": "Required parameters missing to create a payment!",
    "WE_ARE_CLOSES_WE_WILL_BE_BACK_SOON": "We are closed, we’ll be back soon!",
    "WE_ARE_BUSY_RIGHT_NOW_WE_WILL_BE_BACK_SOON": "We are busy right now, we'll be back soon!",
    "PAYMENT_SUCCESSFUL": "Payment successful!",
    "OPPS_EVENT_HAS_EXPIRED": "Oops! Event has expired.",
    "PAYMENT_REQUIRES_ADDITIONAL_CONFIRMATION_FROM_YOU": "Payment requires additional confirmation from you.",
    "CART_DOES_NOT_EXIST": "Cart does not exist!",
    "CANNOT_PLACE_THE_ORDER_NO_ITEMS_IN_THE_CART": "Cannot place the order. No Items in the cart!",
    "PAYMENT_ISSUE": "Payment Issue!",
    "ORDER_PLACED_SUCCESSFULLY": "Order placed successfully!",
    "PAYMENT_METHOD_NOT_FOUND": "Payment method related details not found.",
    "VENDOR_NOT_FOUND": "Vendor details not found.",
    "CANNOT_PLACE_THE_ORDER_SELECTED_SLOT_LIMIT_REACHED": "Cannot place the order. Selected slot limit reached!",
    "SOMETHING_WENT_WRONG_WHILE_PLACING_ORDER": "something went wrong while the placing order!",
    "CANNOT_PLACE_THE_ORDER_SELECTED_SLOT_ITEM_LIMIT_REACHED": "Cannot place the order. Selected slot Item limit reached!",
    "LOGIN_CREDENTIALS_WITH_GOOGLE": "You have to login with google",
    "LOGIN_CREDENTIALS_WITH_FACEBOOK": "You have to login with facebook",
    "LOGIN_CREDENTIALS_WITH_INSTAGRAM": "You have to login with instagram",
    "LOGIN_CREDENTIALS_WITH_APPLE": "You have to login with apple",
    "LOGIN_CREDENTIALS_WITH_PASSWORD": "You have to login with Email and Password",
    "ACCESS_FORBIDDEN": "You are not authorized to access this",
    "PAYMENT_CAPTURING_DETAILS_NOT_FOUND": "Something went wrong while the validating payment!",
    "INVALID_REQUEST_PARAMS_FOR_UPDATE_ORDER_STATUS": "Required parameters missing to update order status.",
    "INVALID_ORDER_STATUS_UPDATE_REQUEST": "Invalid Order status update request. Only online order allow.",
    "ORDER_DOES_NOT_HAVE_TRANSACTION_DETAILS_FOR_REFUND": "Order doesn't have transaction details for refund initiation.",
    "NOT_VALID_PARAM_FOUND_FOR_REFUND": "Required parameters missing to create a payment!",
    "REFUND_ERROR_AT_STRIPE": "Error in refund process at Stripe level.",
    "REGISTER_OPEN_TIME_ISSUES": "Error while opening register. Something went wrong.",
    "PLEASE_CLOSE_THE_REGISTER_FIRST": "Please close the register first.",
    "MENU_NOT_FOUND": "Menu not found.",
    "PLEASE_SETUP_MAX_ORDER_PRODUCT_PER_SLOT_COUNT": "Please setup Order/Product max per slot count.",
    "DISCOUNT_AMOUNT_CANNOT_BE_MORE_THAN_PAYABLE_AMOUNT": "Discount amount can't be more than payable amount.",
    "SKU_IS_ALREADY_EXIST": "SKU is already exist.",
    "NOT_ABLE_TO_TRANSFER_BECAUSE_SKU_IS_MISSING": "Due to missing SKU details, not able to transfer stock.",
    "INVALID_SUPPLIER_VALUE": "Invalid Supplier details.",
    "PRINT_SUCCESS": "PRINT_SUCCESS",
    "NOT_ALLOWED_TO_DELETE_INVALID_VENDOR_DETAILS": "Not allow to delete action due to inappropriate Vendor details.",
    "THIS_ACCOUNT_IS_NO_LONGER_ACTIVE_PLEASE_CONTACT_THE_ADMINISTRATOR_TO_HELP_YOU_FURTHER": "This account is no longer active. Please contact the Administrator to help you further",
    "YOU_ARE_NOT_AUTHORIZED_TO_ACCESS_THE_APP": "You are not authorized to access the app",
    "YOU_NEED_TO_VERIFY_YOUR_EMAIL_ADDRESS_BEFORE_YOU_START_USING_YOUR_ACCOUNT": "You need to verify your email address before you start using your account",
    "CART_NOT_FOUND": "Cart not found.",
    "SINGLE_FEEDBACK_ALLOWED_ONLY": "Feedback can be submitted only once.",
    "APPLE_AUTHORIZATION_ERROR": "Looks like you didn't allow the app to proceed. Please sign in again.",
    "APPLE_TOKEN_ERROR": "Couldn't get a valid token from Apple's server.",
    "PLEASE_OPEN_THE_REGISTER_FIRST": "Please open the register first.",
    "REGISTER_IS_CLOSED": "Register is already closed.",
    "REGISTER_IS_ALREADY_OPEN": "Register is already open.",
    "MENU_IS_SET_AUTOMATED_YOU_CAN_NOT_UPDATE": "Menu is automated so cannot update details.",
    "INVALID_MENU_SAVE_DETAILS": "Invalid Menu save request.",
    "FEEDBACK_NOT_CREATED": "Feedback not submitted!",
    "REPORT_ISSUE_NOT_CREATED": "Issue not submitted!",
    "EMPLOYEE_NOT_FOUND": "Employee details not found.",
    "ASSIGNED_SITE_OUTLET_NOT_FOUND": "Assigned Site outlets not found.",
    "INVALID_TERMINAL_ID": "Invalid Terminal ID",
    "STRIPE_ACCOUNT_NOT_FOUND": "Unable to create stripe location.",
    "PLEASE_SELECT_VALID_DATE_FOR_EMPLOYEE_SHIFT_REPORT_DETAILS": "Select valid time(Month, Week, Day) interval for Staff shift report.",
    "LOYALTY_NOT_CREATED": "Loyalty not created!",
    "SELECT_OUTLET_SHOULD_BE_UNIQUE": "Selected outlets already assigned to other loyalty scheme",
    "SELECT_SITE_SHOULD_BE_UNIQUE": "Selected sites already assigned to other loyalty scheme",
    "SITES_NOT_ASSIGNED_TO_LOYALTY": "Sites is not assgined to loyalty",
    "OUTLET_NOT_ASSIGNED_TO_LOYALTY": "Outlets is not assgined to loyalty",
    "LOYALTY_CREATED_SUCCESSFULLY": "Loyalty created successfully!",
    "LOYALTY_UNARCHIVED_SUCCESSFULLY": "Loyalty unarchived successfully!",
    "LOYALTY_ARCHIVED_SUCCESSFULLY": "Loyalty archived successfully!",
    "LOYALTY_UPDATED_SUCCESSFULLY": "Loyalty updated successfully!",
    "EMAIL_IS_TAKEN": "Email is already taken.",
    "ERR_IN_UPDATE_USER_DETAILS": "Error in update User details.",
    "EMAIL_IS_REQUIRED": "Email is required.",
    "INVALID_EMPLOYEE_SHIFT_REPORT_REQUEST": "Invalid employee shift report request. Provide valid employee details.",
    "CARD_EXISTS": "Card Already Exists!",
    "CARD_CREATED_SUCCESSFULLY": "Card created successfully!",
    "CARD_DELETED_SUCCESSFULLY": "Card deleted successfully!",
    "ADDRESS_SAVED_SUCCESSFULLY": "Address saved successfully!",
    "ADDRESS_DELETED_SUCCESSFULLY": "Address deleted successfully!",
    "EARN_POINT_CANNOT_BE_MORE_THAN_PAYABLE_AMOUNT": "Earn point cannot be more than payable amount",
    "COULD_NOT_APPLY_LOYALTY_AND_DISCOUNT_AT_SAMETIME": "Loyalty and Discount could not apply at same time.",
    "REDEEM_POINT_AMOUNT_CANNOT_BE_MORE_THAN_PAYABLE_AMOUNT": "Redeem point amount cannot be more than payable amount",
    "REDEEM_POINT_CANNOT_BE_MORE_THAN_AVAILABLE_POINT": "Redeem point cannot be more than available point",
    "NOT_LOYALTY_FOUND": "Loyalty not found",
    "NOT_USER_FOUND": "User not found",
    "CREATE_ALL_PREPAID_EMAIL_ERROR": "Create all prepaid email error",
    "NOT_ALLOW_TO_ORDER_FOR_PREPAID": "Not Allow to order from this service type for prepaid menu",
    "PREPAID_ORDER_NOT_ALLOWED_MORE_THAN_MAX_PRODUCTS": "Prepaid order not allowed more than max products",
    "PREPAID_ORDER_DATA_NOT_FOUND": "Prepaid Information not found",
    "OOPS_SITE_NOT_FOUND": "Oops! site not found.",
    "OOPS_INVALID_SERVICE_TYPE_SELECTED": "Oops! invalid service type selected.",
    "PAYMENT_SETTING_IS_NOT_SETUP_IN_SOME_STORES": "Payment details is not found in some restaurants!",
    "TERMINAL_DETAILS_FETCH_FAILED" : "Failed to fetch terminal details.",
  };
  
  
  export const ValidationMsg = {
    numberTypeError: "This field must be a number"
  }