import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { setErrorMessage } from "../../store/actions/StoreActions";
import CustomButton from "../custom-components/custom-button";
import "./error.scss";

const modalStyle = {
    modalContainer: {
        overlay: {
            zIndex: 1200,
        },
        content: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            padding: 0,
            boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)"
        }
    },
    headerTitle: {
        fontFamily: "Europa Bold",
        fontSize: "2.4rem"
    }
}

const ErrorModal = ({ errorMessage, setErrorMessage }) => {
    return (
        <Modal
            isOpen={Boolean(errorMessage)}
            onRequestClose={() => setErrorMessage("")}
            style={modalStyle.modalContainer}
            portalClassName="errorModalContainerClass"
            ariaHideApp={false}
        >
            <div className="height-100">
                <div className="box-container error-modal-header-wrapper header">
                    <div className="error-header-title">Oops!</div>
                </div>
                <div className="error-header-divider"></div>
                <div className="box-container error-modal-content">
                    <div className="error-icon-container">
                        <div className="error-icon-image">
                            <img src={`${process.env.PUBLIC_URL}/error-info.png`} alt="Error-modal" />
                        </div>
                    </div>
                    <div className="error-message-container text-secondary">
                        {errorMessage}
                    </div>
                    <div className="error-close-btn-container">
                        <CustomButton
                            label={"Close"}
                            onClick={() => setErrorMessage("")}
                            className="error-modal-close-btn"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}


const mapStateToProps = (state) => {
    return {
      errorMessage: state.errorMessage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setErrorMessage: (message) => dispatch(setErrorMessage(message))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);