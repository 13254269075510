import "../../../assets/styles/button.scss";
import { ButtonLoader } from "../custom-loader";

const CustomButton = ({ type = 'button', label, className, onClick, children, loader = false, disabled = false }) => {
  return (
    <button
      type={type}
      className={`btn btn-primary btn-rounded ${className ?? ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {loader ? <div className="button-loader-container"><ButtonLoader /></div> :
        label || children}
    </button>
  );
}

export default CustomButton;
