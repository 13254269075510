import "./customLoader.scss";

export const Loader = (props) => {
    const backgroundColor = props.loaderBgColor ? props.loaderBgColor : "rgb(0,0,0,0.02)";
    return <div className='loader-wrapper' style={{backgroundColor: backgroundColor}}>
            <div className="loader"></div>
    </div>
}

export const LoaderWithChildren = (props) => {
    const backgroundColor = props.loaderBgColor ? props.loaderBgColor : "rgb(0,0,0,0.02)";
    return <div className='loader-wrapper' style={{backgroundColor: backgroundColor}}>
        <div className='loader_layer'>
            <div className="loader"></div>
            {props.children}
        </div>
    </div>
}

export const ButtonLoader = () => {
    return <div className="loader"></div>
}