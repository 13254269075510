import React from "react";
import CartItemCard from "./Cart-Item-Card";
import "../Cart.scss";
import { connect } from "react-redux";

const CartList = ({ basket }) => {
    return (
        <div className="box-container">
            <div className="item-list">
                {basket && basket.items && basket.items.length > 0 &&
                  basket.items.map((item, index) => {
                    return <CartItemCard storeName={basket.storeName || ""} item={{...item, outletRef: basket.vendorRef}} index={index} key={index} />
                  })
                }
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
  return {
    basket: state.cart,
  };
};

export default connect(mapStateToProps, null)(CartList);
