import * as actionTypes from "../action-types";

export const setStoreData = (outlet) => {
    return {
      type: actionTypes.SET_STORE_DATA,
      payload: outlet,
    }
}

export const setErrorMessage = (error) => {
  return {
    type: actionTypes.SET_ERROR_MESSAGE,
    payload: error,
  }
}