import { useNavigate } from "react-router-dom";
import CustomButton from "../custom-components/custom-button";
import CustomModal from "../custom-components/custom-modal";

const UnAvailableItemErrorModal = ({showErrors, setShowErrors, items}) => {
    const navigate = useNavigate();

    return <CustomModal
    isModalOpen={showErrors && Boolean(items.length)}
    onRequestClose={() => {
        setShowErrors(false);
        navigate(-1);
    }}
    ariaHideApp={false}
    hideDivider={true}
    modalContainerClass={'cart-validation-modal-container'}
>
    <div className="box-container">
        <p className="mt-0" style={{ fontSize: "2rem" }}> Sorry, the selected items are currently not available. Please remove them from the cart to proceed to checkout.</p>
        <ul className="cart-validation-modal-list">
            {
                items.map((itemName, index) =>
                    <li key={index} className="cart-validation-modal-item">{itemName} </li>)
            }
        </ul>
        <div className="error-pop-up-button">
            <CustomButton className="btn-block" onClick={() => {
                setShowErrors(false);
                navigate(-1);
            }}>
                <span>Okay</span>
            </CustomButton>
        </div>
    </div>
</CustomModal>
}

export default UnAvailableItemErrorModal;