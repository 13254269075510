import { updateObject } from "../actions/utility";


const storedCart = JSON.parse(localStorage.getItem("cartItems")) || {};

export const productsInitialState = {
    products: [],
    productsError: null,
    loadingProducts: true,
    vendorId: null,
    cart: { ...storedCart },
  };


export const setCartLoading = (state, action) => {
    return updateObject(state, {
      cartLoading: action.payload,
    })
}

export const addItemsToCart = (state, action) => {
    localStorage.setItem("cartItems", JSON.stringify(action.payload));
    return updateObject(state, {
      cart: action.payload,
    });
};

export const setQuantityLoader = (state, action) => {
    return updateObject(state, {
      quantityLoader: action.payload
    })
}

export const emptyCart = (state, action) => {
  localStorage.removeItem("cartItems");
  return updateObject(state, {
    cart: {},
  });
};