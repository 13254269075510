import { network } from "../common/network";
import apiConstant from "../constants/apiConstants"


export const getStoreDetails = async (outletId) => {
    const uri = `${apiConstant.kioskURI}/outlet/${outletId}`;
    const response = await network(uri);
    if(response?.success){
        return response.data;
    }
    throw response;
}

export const logoutUser = async (params) => {
    const uri =`${apiConstant.userURI}/kiosk/logout`;
    const response = await network(uri, "POST", params);
    if(response?.success){
        return response?.data;
    }
    throw response;
}

export const getStoreKioskMenu = async (outletId, params) => {
    const uri = `${apiConstant.kioskURI}/outlet/${outletId}/menus`;
    const response = await network(uri, "GET", params);
    if(response?.success){
        return response?.data;
    }
    throw response;
}

export const getStoreKioskMenuItems = async (outletId, menuId, params) => {
    const uri = `${apiConstant.kioskURI}/outlet/${outletId}/menus/${menuId}`;
    const response = await network(uri, "GET", params);
    if(response?.success){
        return response?.data;
    }
    throw response;
}

export const getCustomAllergensAndDietaryByMenu = async (outletId, menuId) => {
    const response = await network(`${apiConstant.kioskURI}/outlet/${outletId}/menus/${menuId}/get-custom-allergen-dietary`);
    if(response.success) return response.data;
    throw response.message;
}

export const checkStockAlert = async (outletId, params) => {
    const uri = `${apiConstant.kioskURI}/outlet/${outletId}/check-items-stock`;
    const response = await network(uri, "POST", params);
    if(response.success) return response.data;
    throw response;
}

export const createCheckout = async (outletId, params) => {
    const uri = `${apiConstant.kioskURI}/outlet/${outletId}/checkout`;
    const response = await network(uri, "POST", params);
    if(response.success) return response.data;
    throw response;
}

export const getOrderDetail = async (outletId, orderId) => {
    const uri = `${apiConstant.kioskURI}/outlet/${outletId}/orders/${orderId}`;
    const response = await network(uri, "GET");
    if(response?.success) return response?.data;
    throw response;
}

export const verifyAdyenTransactionStatus = async (outletId, params) => {
    const uri = `${apiConstant.kioskURI}/outlet/${outletId}/verify-adyen-transaction-status`;
    const response = await network(uri, "POST", params);
    return response;
}

export const getCurrentUserDetails = async () => {
    const uri = `${apiConstant.userURI}/me`;
    const response = await network(uri, "GET");
    if(response?.success) return response?.data;
    throw response;
}
