import * as actionTypes from "../action-types";
import { addItemsToCart, emptyCart, productsInitialState, setCartLoading, setQuantityLoader } from "./productReducer";
import { setErrorMessage, setStoreData, storeInitialState } from "./storeReducer";
import { setUser, userInitialState } from "./userReducer";

const initialState = {
    ...userInitialState,
    ...storeInitialState,
    ...productsInitialState
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return setUser(state, action);
        case actionTypes.SET_STORE_DATA:
            return setStoreData(state, action);
        case actionTypes.SET_CART_LOADING:
            return setCartLoading(state, action);
        case actionTypes.ADD_ITEMS_CART_ACTION:
            return addItemsToCart(state, action);
        case actionTypes.SET_QUANTITY_LOADER:
            return setQuantityLoader(state, action);
        case actionTypes.EMPTY_CART_ACTION:
            return emptyCart(state, action);
        case actionTypes.SET_ERROR_MESSAGE:
            return setErrorMessage(state, action);
        default:
            return state;
    }
}

export default reducer;
