import React from "react";
import Modal from 'react-modal';
import { AiOutlineClose } from "react-icons/ai";
import "../../../assets/styles/header.scss";

const modalStyle = {

    modalContainer: {
        overlay: {
            // margin: 'auto',
            // maxWidth: '1000px',
            zIndex: 1000
        },
        content: {
            top: '0',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            width: '100vw',
            height: 'calc(100*var(--vh, 1vh))',
            padding: 0,
            // margin: 'auto',
            // maxWidth: '768px'
        }
    },
    headerTitle: {
        fontFamily: "Europa Bold",
        fontSize: "1.4rem"
    },
    divider: {
        display: "flex",
        height: "2px",
        backgroundColor: "#000",
        width: "100%"
    }
}

const CustomModal = ({showHeader=true, ...modalProps  }) => {

    return (
        <Modal
            isOpen={modalProps.isModalOpen}
            onRequestClose={modalProps.onRequestClose}
            style={modalStyle.modalContainer}
            portalClassName={modalProps.modalContainerClass}
            ariaHideApp={false}
        >
            <div>
                {showHeader ?  
                    <div style={modalStyle.headerTitle} className={modalProps.headerClass || "box-container custom-header-wrapper header"}>
                        <div className="header-title">{modalProps.title}</div>
                        {modalProps.isClosable && <AiOutlineClose onClick={modalProps.onRequestClose} size={45} />}
                    </div> : 
                null}
                {!modalProps.hideDivider && <div style={{...modalStyle.divider, backgroundColor: modalProps.backgroundColor || "#000"}}></div>}
                {modalProps.children}
            </div>
        </Modal>
    );
}

export default CustomModal;