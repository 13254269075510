import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import CustomButton from "../custom-components/custom-button";
import CustomModal from "../custom-components/custom-modal";
import "./logout.scss";
import { apiService } from "../../services";
import jwtService from "../../services/jwtService";
import { setUser } from "../../store/actions/UserActions";
import { emptyCart } from "../../store/actions/ProductActions";
import { connect } from "react-redux";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { setErrorMessage } from "../../store/actions/StoreActions";

const validationSchema = yup.object().shape({
  userName: yup.string().trim().required().label("Username"),
  password: yup.string().required().label("Password"),
});

const LogoutModal = ({ isLogoutModalOpen, setIsLogoutModalOpen, storeData, ...props }) => {
  const [logoutLoading, setLogoutLoading] = useState(false);
  const { storeId } = useParams();
  const navigate = useNavigate();
  const onLogoutSubmit = (values) => {
    setLogoutLoading(true);
    apiService.logoutUser({
      email: values.userName,
      password: values.password,
      vendorId: storeData._id,
      refreshToken: jwtService.getRefreshToken(),
    }).then(res => {
      props.setUser(null);
      props.removeCart();
      navigate(`/store/${storeId}`);
      jwtService.logout();
    }).catch(err => {
      props.setErrorMessage(err?.message || "")
    }).finally(() => setLogoutLoading(false))
  }
  return (
    <CustomModal
      isModalOpen={isLogoutModalOpen}
      onRequestClose={() => {
        setIsLogoutModalOpen(false);
      }}
      title={"Insert username and password to Log Out"}
      ariaHideApp={false}
      hideDivider={true}
      modalContainerClass={"logout-modal-container"}
      isClosable={true}
    >
      <div className="box-container log-out-container">
        <div className="log-out-form-wrapper">
          <Formik
            initialValues={{ userName: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={onLogoutSubmit}
          >
            {(props) => (
              <div className="log-out-form-container">
                <div className="form-item-container">
                  <div className="form-item">
                    <label className="form-label">Username</label>
                    <input
                      {...props.getFieldProps("userName")}
                      name="userName"
                      type="text"
                      placeholder="Insert Username"
                      className="form-input"
                      autoComplete="new-password"
                    />
                    <ErrorMessage
                      name="userName"
                      component="div"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className="form-item-container">
                  <div className="form-item">
                    <label className="form-label">Password</label>
                    <input
                      {...props.getFieldProps("password")}
                      name="password"
                      type={"password"}
                      placeholder="Insert Password"
                      className="form-input"
                      autoComplete="new-password"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="form-error"
                    />
                    <div className="form-item-container">
                      <div className="form-item">
                        <CustomButton
                          type="submit"
                          onClick={props.handleSubmit}
                          label="Log Out"
                          loader={logoutLoading}
                          disabled={logoutLoading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </CustomModal>
  );
};

const mapStateToProps = (state) => {
  return {
    storeData: state.storeData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(setUser(user))
    },
    removeCart: () => dispatch(emptyCart()),
    setErrorMessage: (message) => dispatch(setErrorMessage(message)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutModal);
