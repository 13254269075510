import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux"
import { basketToRequest, createCheckoutRequestBody, genrateUniqueId } from "../../common/utils";
import { emptyCart, injectCheckoutDetailsToCart, injectTipToCart } from "../../store/actions/ProductActions";
import { LoaderWithChildren } from "../custom-components/custom-loader";
import CheckoutForm from "./CheckoutForm";
import UnAvailableItemErrorModal from "./UnAvailableItemErrorModal";
import StockAlertModal from "./StockAlertModal";
import PaymentInProgress from "./PaymentInProgress";
import Header from "./Header";

// import { apiService } from "../../services";

import './checkout.scss';
import { checkStockAlert } from "../../services/api.service";
import { apiService } from "../../services";
import { setErrorMessage } from "../../store/actions/StoreActions";

const Checkout = ({storeData, basket, storeUser, cartLoading, injectCheckoutDetailsToCart, removeCart, setErrorMessage}) => {
    const navigate = useNavigate();
    const { storeId } = useParams();
    const { id, userName, vendorRef, terminalId, ...serviceData } = storeUser;
    
    const [stockError, setStockError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paymentError, setPaymentError] = useState(false);
    const [paymentInProgress, setPaymentInProgress] = useState(false);
    const [unavailableItems, setUnavailableItems] = useState([]);
    const [showErrors, setShowErrors] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [formData, setFormData] = useState(basket && basket.userDetails && Object.keys(basket.userDetails).length ? basket.userDetails : {});
    let verificationAPICallCounter = 0;

    useEffect(() => {
        document.body.style.position = "fixed";
        checkStockAlert(storeId, { items: (basket?.items || [] ) }).catch(res => setStockError(res.errors.errors));
        return () => document.body.style.position = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let newArray = [];
        if (basket && basket.items && basket.items.length && storeData && storeData.timezone) {
            apiService.getStoreKioskMenu(storeId, {serviceType: 'kiosk', timezone: storeData.timezone,  siteRef: storeData?.site?.siteRef})
            .then((res) => {
                const activeMenu = res.map((menu) => menu.menu?._id);
                for (let j = 0; j < basket.items.length; j++) {
                    if (!activeMenu.includes(basket.items[j]?.menu?.menuRef)) {
                        newArray.push(basket?.items[j]?.itemName);
                    }
                }
                setUnavailableItems(newArray);
                if (newArray.length) {
                    setShowErrors(true);
                }
            }).catch(err => {
                if(err && err.messageCode && err.messageCode === "NOT_FOUND"){
                    navigate(-1);
                    setErrorMessage('Sorry, selected items are not available!');
                }else{
                    setErrorMessage(err?.message || "");
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeData]);

    useEffect(() => {
        const handleStatusChange = () => {
          setIsOnline(navigator.onLine);
        };
    
        window.addEventListener('online', handleStatusChange);
        window.addEventListener('offline', handleStatusChange);
    
        return () => {
          window.removeEventListener('online', handleStatusChange);
          window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    const handleSubmit = (values) => {
        setFormData(values);
        handleFreeCheckout(values);
    }

    const handleFreeCheckout = async (checkoutDetails) => {
        setLoading(true);
        const reqBody = createCheckoutRequestBody(basket, storeId, serviceData, checkoutDetails, { marketing });
        const reqBodyWithCartId = {
            ...reqBody,
            cartId: genrateUniqueId(),
        }
        setPaymentError(false);
        setPaymentInProgress(true);
        apiService.createCheckout(storeId, reqBodyWithCartId).then((res) => {
            if(res && res._id){
                removeCart();
                navigate(`/store/${storeId}/order-detail/${res._id}`, { replace: true });
            }
        }).catch(err => {
            onCheckoutFailure(err, reqBodyWithCartId, checkoutDetails);
        })
        setLoading(false);
    }

    const verifyTransactionStatus = (checkoutRequestBody) => {
        const params = {
            paymentType: checkoutRequestBody.paymentType,
            adyen: checkoutRequestBody.adyen,
            cartId: checkoutRequestBody.cartId,
        }
        apiService.verifyAdyenTransactionStatus(storeId, params)
        .then(res => {
            if(res.success){
                if(res.data && res.data._id){
                    removeCart();
                    navigate(`/store/${storeId}/order-detail/${res.data._id}`, { replace: true });
                }else{
                    setErrorMessage(res?.message || "");
                }
            }
        }).catch(errResponse => {
            onFailureVerifyTrxStatus(errResponse, checkoutRequestBody);
        })

    }

    const onFailureVerifyTrxStatus = (errResponse, checkoutRequestBody) => {
        if(errResponse.message && (errResponse.message === "Network Error" || errResponse.message === 'No Internet Connection!')){
            setIsOnline(navigator.onLine);
            setTimeout(() => {
                verifyTransactionStatus(checkoutRequestBody)
            }, 1000);
            return
        }else if (errResponse &&
            errResponse.message === 'UN_HANDLE_RESPONSE' &&
            verificationAPICallCounter < 3
          ) {
            verificationAPICallCounter += 1;
            setTimeout(() => verifyTransactionStatus(checkoutRequestBody), 500);
            return;
        }
        else {
            setErrorMessage(errResponse?.message || "");
            setPaymentInProgress(false);
            setPaymentError(true);
        }
    }


    const onCheckoutFailure = (err, reqBody, checkoutDetails) => {
        if(err){
            if(err.message && (err.message === "Network Error" || err.message === 'UN_HANDLE_RESPONSE' || err.message === 'No Internet Connection!')){
                setIsOnline(navigator.onLine);
                setTimeout(() => {
                    verifyTransactionStatus(reqBody);
                }, 1000);
                return;
            }
            // else if(err.message && err.message.length && err.message.includes("Shopper cancelled tx")){
            //     navigate(-1);
            // }
            else {
                if(err?.message?.includes("Shopper cancelled tx")) {
                    setPaymentError('Payment was cancelled by the user')
                } else {
                    setPaymentError(true);
                    setErrorMessage(err?.message || "");
                }
                setPaymentInProgress(false);
            }
            injectCheckoutDetailsToCart({
                ...checkoutDetails,
                marketing,
            });
        }
    }


    const tryCheckoutAgain = () => {
        handleSubmit(formData);
    }

    if(paymentError || paymentInProgress) {
        return <PaymentInProgress 
            storeData={storeData} 
            isOnline={isOnline}
            paymentError={paymentError} 
            onClickBack={() => {
                setPaymentError(false);
                setPaymentInProgress(false);
            }}
            onClickTryAgain={tryCheckoutAgain}
        />
    }

    return <div className="checkout-page">
        <Header storeData={storeData} hasBack={true}/>
        <CheckoutForm formData={formData} handleSubmit={handleSubmit} basket={basket} loading={loading} setMarketing={setMarketing} marketing={marketing}/>
        <UnAvailableItemErrorModal
            items={unavailableItems}
            setShowErrors={setShowErrors}
            showErrors={showErrors}
        />
        <StockAlertModal
            items={basketToRequest(basket, serviceData).items}
            setStockError={setStockError}
            stockError={stockError}
        />
        {cartLoading && <div className="loader-overlay"><LoaderWithChildren /></div>}
    </div>
}

const mapStateToProps = (state) => {
    return {
        storeData: state.storeData,
        basket: state.cart,
        cartLoading: state.cartLoading,
        storeUser: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        injectTipToCart: (tip) => dispatch(injectTipToCart(tip)),
        injectCheckoutDetailsToCart: (checkoutDetails) => dispatch(injectCheckoutDetailsToCart(checkoutDetails)),
        removeCart: () => dispatch(emptyCart()),
        setErrorMessage: (message) => dispatch(setErrorMessage(message)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);