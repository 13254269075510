/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BiFilterAlt } from "react-icons/bi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CustomDropdown } from "../custom-components/custom-dropdown";
import CustomModal from "../custom-components/custom-modal";
import CategoryCard from "./Category-Card";
import ItemFilterModal from "./Item-Filter-Modal";
import "./menu-item-list.scss";
import { createValuesIdsArray, getAllMergedCustomAttributes, getInBasketCount, restructureMenu } from "../../common/utils";
import { apiService } from "../../services";
import { LoaderWithChildren } from "../custom-components/custom-loader";
import CustomButton from "../custom-components/custom-button";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import LogoutModal from "../Menus/LogoutModal";
import { setErrorMessage } from "../../store/actions/StoreActions";

const MenuItemsList = (props) => {
    const { outlet, dropdownMenuList, storeData, basket, storeFees } = props;
    const navigate = useNavigate();
    const { storeId } = useParams();

    const [searchQueryParams] = useSearchParams();
    const selectedMenuRef = searchQueryParams.get("menuId");

    const [enableFilters, setEnableFilters] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [selectedMenu, setSelectedMenu] = useState(null);
    // const [dietaryChecked, setDietaryChecked] = useState([]);
    // const [allergenChecked, setAllergenChecked] = useState([]);
    const [originalMenu, setOriginalMenu] = useState([]);
    const [categories, setCategories] = useState([]);
    const [menuLoading, setMenuLoading] = useState(false);
    const [currentCategory, setCurrentCategory] = useState("");
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    // const [dynamicAllergens, setDynamicAllergens] = useState([]);
    // const [dynamicDietary, setDynamicDietary] = useState([]);

    const [dynamicCustomAttributes, setDynamicCustomAttributes] = useState([]);
    const [attributeValueIds, setAttributeValueIds] = useState([]);

    const inBasketCount = getInBasketCount(basket);

    useEffect(() => {
        document.body.style.position = "fixed";
        return () => document.body.style.position = null;
    }, []);

    // const getCustomAllergensAndDietaryByMenu = (menuId) => {
    //     setMenuLoading(true);
    //     apiService.getCustomAllergensAndDietaryByMenu(storeId, menuId)
    //         .then((res) => {
    //             setDynamicAllergens([...res?.customAllergens]);
    //             setAllergenChecked(new Array([...res?.customAllergens].length).fill(false))
                
    //             setDynamicDietary([...res?.customDietary]);
    //             setDietaryChecked(new Array([...res?.customDietary].length).fill(false))
    //         }).catch((err) => {
    //             console.log(err);
    //             setDynamicAllergens([]);
    //             setAllergenChecked([]);
    //         }).finally(() => setMenuLoading(false))
    // }

    useEffect(() => {
        if(storeId && selectedMenuRef){
            setMenuLoading(true);
            apiService.getStoreKioskMenuItems(storeId, selectedMenuRef, { serviceType: "kiosk", siteRef: outlet?.site?.siteRef })
            .then(menuItemsRes => {
                setOriginalMenu(restructureMenu(menuItemsRes));
                const mapCustomAttributes = getAllMergedCustomAttributes(menuItemsRes);
                setDynamicCustomAttributes(mapCustomAttributes);
                if(menuItemsRes && menuItemsRes.length){
                    setCurrentCategory(menuItemsRes[0].categoryName);
                }
            }).catch(err => {
                props.setErrorMessage(err?.message || "")
            }).finally(() => setMenuLoading(false))
            // getCustomAllergensAndDietaryByMenu(selectedMenuRef);
        }
    },[selectedMenuRef]);

    useEffect(() => {
        handleFilterChange();
    },[originalMenu])

    const openModal = () => {
        setEnableFilters(true);
    }

    const closeModal = () => {
        setEnableFilters(false);
    }

    const onSelectMenu = (option) => {
        setSelectedMenu(option);
        navigate(`/store/${storeId}/menus?menuId=${option.value}`, { replace: true })
    };

    function scrollHandler(event) {
        for (let i = 0; i < categories.length; i++) {
            let categoryElement = document.getElementById(categories[i].categoryName.trim());
            if (categoryElement) {
                if (
                    categoryElement.getBoundingClientRect().top <= 180 &&
                    categoryElement.getBoundingClientRect().bottom > 180
                ) {
                    setCurrentCategory(categories[i].categoryName.trim());
                    document.getElementById(categories[i].categoryName.trim() + "-nav").scrollIntoView();
                }
            }
        }
    }

    const handleFilterChange = () => {
        const selectedCustomAttributes = [...dynamicCustomAttributes];

        let selectedAttributesValueIdsToIncludeItems = [];
        let selectedAttributesValueIdsToExcludeItems = [];
        let mapItemsToIncludeByCustomAttributeValues = {};
        let mapItemsToExcludeByCustomAttributeValues = {};

        selectedCustomAttributes.forEach((attribute) => {
            const generateSelectedValuesIds = createValuesIdsArray(
              attribute.attributeObjects,
              attribute.checkedValues,
              attribute.isExcluded
            );

            selectedAttributesValueIdsToIncludeItems = [
                ...selectedAttributesValueIdsToIncludeItems, 
                ...generateSelectedValuesIds.includeItemRes
            ];
            selectedAttributesValueIdsToExcludeItems = [
                ...selectedAttributesValueIdsToExcludeItems, 
                ...generateSelectedValuesIds.exludeItemRes
            ]
        });

        let filteredCategories = [...originalMenu];

        for (let i = 0; i <= filteredCategories.length; i++) {
          if (filteredCategories[i]?.items?.length) {
            for (let j = 0; j <= filteredCategories[i].items.length; j++) {
              if (filteredCategories[i]?.items[j]?.customAttributes?.length) {
                for (
                  let m = 0;
                  m <= filteredCategories[i].items[j].customAttributes.length;
                  m++
                ) {
                  if (
                    filteredCategories[i].items[j].customAttributes[m]
                      ?.attributeValues?.length
                  ) {
                    for (
                      let n = 0;
                      n <=
                      filteredCategories[i].items[j].customAttributes[m]
                        .attributeValues.length;
                      n++
                    ) {
                      const attributeValueId =
                        filteredCategories[i]?.items[j]?.customAttributes[m]
                          .attributeValues[n]?._id;
                      if (
                        attributeValueId &&
                        selectedAttributesValueIdsToIncludeItems?.includes(
                          attributeValueId
                        )
                      ) {
                        if (
                          mapItemsToIncludeByCustomAttributeValues[
                            attributeValueId
                          ]
                        ) {
                          mapItemsToIncludeByCustomAttributeValues[
                            attributeValueId
                          ] = [
                            ...mapItemsToIncludeByCustomAttributeValues[
                              attributeValueId
                            ],
                            filteredCategories[i].items[j].itemRef,
                          ];
                        } else {
                          mapItemsToIncludeByCustomAttributeValues[
                            attributeValueId
                          ] = [filteredCategories[i].items[j].itemRef];
                        }
                      }
                      if (
                        attributeValueId &&
                        selectedAttributesValueIdsToExcludeItems?.includes(
                          attributeValueId
                        )
                      ) {
                        if (
                          mapItemsToExcludeByCustomAttributeValues[
                            attributeValueId
                          ]
                        ) {
                          mapItemsToExcludeByCustomAttributeValues[
                            attributeValueId
                          ] = [
                            ...mapItemsToExcludeByCustomAttributeValues[
                              attributeValueId
                            ],
                            filteredCategories[i].items[j].itemRef,
                          ];
                        } else {
                          mapItemsToExcludeByCustomAttributeValues[
                            attributeValueId
                          ] = [filteredCategories[i].items[j].itemRef];
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        const itemsRefToInclude = [
          ...new Set(
            Object.values(mapItemsToIncludeByCustomAttributeValues).flat()
          ),
        ];
        const itemsRefToExclude = [
          ...new Set(
            Object.values(mapItemsToExcludeByCustomAttributeValues).flat()
          ),
        ];

        if(itemsRefToInclude?.length){
            filteredCategories = filteredCategories.map((cat, index) => {
                return {
                    ...cat,
                    items: cat.items.filter((item) => {
                        if(itemsRefToInclude.indexOf(item.itemRef) !== -1){
                            return true;
                        }
                        return false;
                    })
                }
            })
        }

        if(itemsRefToExclude?.length){
            filteredCategories = filteredCategories.map((cat, index) => {
                return {
                    ...cat,
                    items: cat.items.filter((item) => {
                        if(itemsRefToExclude.indexOf(item.itemRef) !== -1){
                            return false;
                        }
                        return true;
                    })
                }
            })
        }

        filteredCategories = filteredCategories.filter(
            (cat) => cat.items.length !== 0,
        );

        setCategories(filteredCategories);
        setEnableFilters(false);
    }

    const el = document.querySelector(".category-list-scroll");

    if (el) {
        el.addEventListener("scroll", scrollHandler);
    }

    return (
        <div className={`${!Boolean(Object.keys(inBasketCount).length) ? "menu-long-list" : ""} menu-container`}>
            <div className="box-container custom-header-wrapper header">
                <div className="header-left">
                    {dropdownMenuList && dropdownMenuList.length > 1 && <AiOutlineArrowLeft size={40} onClick={() => { navigate(-1); }} />}
                    <div className="header-title">
                        <div className="fs-heading">{outlet?.displayName} - Pick Up</div>
                    </div>
                </div>
                <div className="logout-section">
                    <div className="logout-button-link" onClick={() => setIsLogoutModalOpen(true)}>Log Out</div>
                </div>
            </div>
            {menuLoading ? <LoaderWithChildren>Please wait while we are loading the menu</LoaderWithChildren> : 
            <React.Fragment>
                <div className="banner">
                    <div className={`box-container banner-filter-wrapper ${(!dropdownMenuList || dropdownMenuList.length === 1) ? 'filter-flex-end' : ''}`}>
                        {dropdownMenuList && dropdownMenuList.length > 1 ? 
                        <div className="menu-dropdown-section">
                            <CustomDropdown
                                options={dropdownMenuList}
                                onSelect={onSelectMenu}
                                value={dropdownMenuList && dropdownMenuList.length ? dropdownMenuList.find((menuObj) => menuObj.value === selectedMenuRef)?.label : null}
                                placeHolder={"Select Menu"}
                                dropDownContainerClass="select-menu-container"
                                dropDownHeaderClass="select-menu-header"
                                dropDownListContainerClass="select-menu-list-container"
                                dropDownListClass="select-menu-list"
                                dropDownItemClass="select-menu-item"
                            />
                            <div className="menu-selection-label">Select Menu</div>
                        </div>: null }
                        <div className="filter-button" onClick={openModal}>
                            <span style={{ paddingRight: "10px" }}>Filters</span>
                            <BiFilterAlt size={40} />
                        </div>
                    </div>
                </div>
                <div className="category-nav-sub-heading">
                    {categories.map((category) => (
                        <Fragment key={category._id}>
                            <button
                                id={category.categoryName.trim() + "-nav"}
                                className={
                                    category.categoryName.trim() === currentCategory
                                        ? "category-nav category-nav-active"
                                        : "category-nav"
                                }
                                onClick={() => {
                                    let element = document.getElementById(
                                        category.categoryName.trim()
                                    );
                                    if (element) {
                                        el?.removeEventListener("scroll", scrollHandler);
                                            element.scrollIntoView({
                                            behavior: "auto",
                                            block: "start",
                                        });
                                        setTimeout(() => setCurrentCategory(category.categoryName.trim()), 10);
                                    }
                                }}
                            >
                                {category.categoryName}
                            </button>
                        </Fragment>
                    ))}
                </div>
                <div className={`${Boolean(Object.keys(inBasketCount).length) ? "category-list" : "category-long-list"} category-list-scroll`}>
                    <div className="box-container">
                        {categories && categories.length ? categories.map((ctgry, index) => { 
                            return <CategoryCard category={ctgry} inBasketCount={inBasketCount} key={ctgry._id} vendorRef={storeId} />
                        }) : <p className="text-center" style={{ fontSize: "1.8rem" }}>No items available</p> }
                    </div>
                </div>
                <div className="sticky-footer menu-bottom-fixed">
                    <div className="separator separator-theme"></div>
                    <div className="menu-button-container">
                        <div className="box-container menu-bottom-button">
                            {(basket?.subTotal || 0) >= storeFees?.absoluteMinOrder ? 
                                <CustomButton className="btn-block" onClick={() => navigate(`/store/${storeId}/basket`)}>
                                    <div className="btn-flex-row">
                                        <div>
                                            {basket && basket.items && basket.items.length !== 0 ? 
                                                <div className="circle">{Object.keys(inBasketCount).length}</div> 
                                            : null}
                                        </div>
                                        <div>View Basket</div>
                                        <div>
                                            {basket?.subTotal !== undefined ?
                                                <FormattedNumber
                                                    style="currency"
                                                    value={basket.subTotal || 0}
                                                    minimumFractionDigits={2}
                                                    maximumFractionDigits={2}
                                                    currency={storeData?.currency || "gbp"}
                                                />
                                            :null}
                                        </div>
                                    </div>
                                </CustomButton> :
                                <CustomButton disabled={true} className="btn-block btn-disabled" onClick={() => navigate(`/store/${storeId}/basket`)}> 
                                    <div className="btn-flex-row"> 
                                        <div> 
                                            {basket && basket.items && basket.items.length !== 0 ? 
                                            <div className="circle circle-disabled"> 
                                                {Object.keys(inBasketCount).length}  
                                            </div>  
                                            : null} 
                                        </div> 
                                        <div> 
                                            <span>Min. Order {" "}</span>
                                            <FormattedNumber
                                                style="currency"
                                                value={storeFees?.absoluteMinOrder}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                                currency={outlet.currency || "gbp"}
                                            /> 
                                        </div> 
                                        <div> 
                                            {basket?.subTotal !== undefined ? 
                                                <FormattedNumber
                                                    style="currency"
                                                    value={basket?.subTotal}
                                                    minimumFractionDigits={2}
                                                    maximumFractionDigits={2}
                                                    currency={outlet.currency || "gbp"}
                                                /> 
                                                : null}
                                        </div> 
                                    </div>
                                </CustomButton>
                            }
                        </div>
                    </div>
                </div>
                <CustomModal
                    isModalOpen={enableFilters}
                    onRequestClose={closeModal}
                    title={"Filters"}
                    isClosable={true}
                    hideDivider={false}
                >   
                    <ItemFilterModal
                        handleFilterChange={handleFilterChange}
                        dynamicCustomAttributes={dynamicCustomAttributes}
                        setDynamicCustomAttributes={setDynamicCustomAttributes}
                        setAttributeValueIds={setAttributeValueIds}
                        attributeValueIds={attributeValueIds}
                    />
                </CustomModal>
                <LogoutModal 
                    isLogoutModalOpen={isLogoutModalOpen} 
                    setIsLogoutModalOpen={setIsLogoutModalOpen} 
                />
            </React.Fragment>}
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        basket: state.cart,
        storeFees: state.user,
        storeData: state.storeData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setErrorMessage: (message) => dispatch(setErrorMessage(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemsList);