import { ErrorMessage } from "formik"
import { CustomInput } from "../custom-components/custom-input"

export const YourDetailsForm = ({ props, handleChangeField }) => {
    return <div className="checkout-info-section">
        <div className="checkout-page-sub-title">Your Details</div>
        <div className="details-sub-section">
            <div className="d-flex justify-content-between form-group">
                <span className="text-secondary input-label">First Name*</span>
                <div className="flex-end w-50">
                    <div className="checkout-input-group">
                        <input type="text" {...props.getFieldProps("name")} name="name" placeholder="Insert first name" className={"checkout-input"} />
                        {props.touched.name && props.errors.name ? <ErrorMessage name="name" component="div" className="error" /> : null}
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between form-group">
                <span className="text-secondary input-label">Last Name</span>
                <div className="flex-end w-50">
                    <div className="checkout-input-group">
                        <input type="text" {...props.getFieldProps("lastName")} name="lastName" placeholder="Insert last name" className={"checkout-input"} />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between form-group">
                <span className="text-secondary input-label">Email (Customer Receipt - optional)</span>
                <div className="flex-end w-50">
                    <div className="checkout-input-group">
                        <input type="email" {...props.getFieldProps("email")} name="email" placeholder="Insert email" className={"checkout-input"} />
                        {props.touched.email && props.errors.email ? <ErrorMessage name="email" component="div" className="error" /> : null}
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between w-100 form-group">
                <CustomInput onChange={(value) => handleChangeField(props, 'note', value)} value={props.values.note} name="note" placeHolder="Add note" className={"checkout-input w-100"} />
            </div>
        </div>
    </div>
}