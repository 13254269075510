import React from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import './App.scss';
import Layouts from './components/container/Layout';
import ErrorModal from './components/Error-Component/ErrorModal';
import BaseRouter from "./router";
import { ErrorFallback } from './components/Error-Component/ErrorFallback';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import Authentication from './components/authentication';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const RouterWithErrorBoundary = withErrorBoundary(BaseRouter, {
  FallbackComponent: ErrorFallback,
});

function App() {
  return (
    <BrowserRouter>
      <Authentication>
        <Layouts>
          <IntlProvider locale="en">
            <RouterWithErrorBoundary />
            <ToastContainer limit={1} />
            <ErrorModal />
          </IntlProvider>
        </Layouts>
      </Authentication>
    </BrowserRouter>
  );
}

export default App;
