import axios from "axios";
import apiConstant from "../constants/apiConstants";
import { ErrorMsg } from "../constants/ErrorConstant";
export const network = async(
    url,
    method = "GET",
    payload,
    customHeaders,
) => {
    const apiURL = apiConstant.serverURL + url;
    axios.defaults.timeout = 0;
    
    const configs = {
        headers: {
          hostname: window.location.hostname,
          apptype: 'kiosk'
        },
        params: {},
        timeout: 0,
    };
    
    const token = await localStorage.getItem("jwt_access_token");
    
    if (token) {
        configs.headers = {
          ...configs.headers,
          Authorization: "Bearer " + token,
        };
    }

    if (customHeaders) {
        configs.headers = {
          ...configs.headers,
          ...customHeaders
        }
    }

    if (method === "GET") {
        configs.params = payload;
    }

    if (method === "DELETE" && payload) {
        configs.data = payload;
    }

    switch (method) {
        case "POST":
          try {
            const res = await axios.post(apiURL, payload, configs);
            return responseHandler(res);
          } catch (err) {
            throw errorHandler(err);
          }
        case "PUT":
          try {
            const res = await axios.put(apiURL, payload, configs);
            return responseHandler(res);
          } catch (err) {
            throw errorHandler(err);
          }
        case "PATCH":
          try {
            const res = await axios.put(apiURL, payload, configs);
            return responseHandler(res);
          } catch (err) {
            throw errorHandler(err);
          }
        case "DELETE":
          try {
            const res = await axios.delete(apiURL, configs);
            return responseHandler(res);
          } catch (err) {
            throw errorHandler(err);
          }
        default:
          try {
            const res = await axios.get(apiURL, configs);
            return responseHandler(res);
          } catch (err) {
            throw errorHandler(err);
          }
      }
    };

/**
 * @description This function is used for handling the API response.
 * @param {AxiosResponse} res
 * @returns
 */
export const responseHandler = (res) => {
    if (!res) throw new Error("Network Err!");
    const response = {
      status: res.status,
      data: null,
      success: false,
      message: ErrorMsg[res.data?.messageCode] || res.data?.message,
      errors: null,
      totalRecord: 0,
      messageCode: res.data.messageCode,
    };
  
    switch (res.status) {
      case 401:
        return {
          ...response,
          errors: res.data?.errors,
        };
      default:
        return {
          ...response,
          data: res.data.data,
          totalRecord: res.data.totalRecord,
          success: res.status === 200,
        };
    }
  };

  /**
 * @description This function is used for handling the API errors.
 * @param {AxiosError} err
 * @returns
 */
export const errorHandler = (err) => {
    if (err.response && err.response.data) {
      return {
        status: err.response.status,
        data: null,
        message: ErrorMsg[err.response.data.messageCode] || ErrorMsg[err.response.data.message] || err.response.data.message,
        errors: (err).response.data.errors,
        success: false,
        messageCode: err.response.data.messageCode,
        totalRecord: 0,
      }
    }
    return {
      status: 500,
      data: null,
      message: err.message,
      errors: err.errors,
      success: false,
      messageCode: "",
      totalRecord: 0,
    };
  };