import React from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";

const formatOutletAddress = (storeData) => {
    let str = "";
    if(storeData?.address) {
        str += storeData?.address;
    }
    if(storeData?.address2) {
        str += `, ${storeData?.address2}`;
    }
    if(storeData?.city) {
        str += `, ${storeData?.city}`;
    }
    return str;
}

const Header = ({ storeData, hasBack = false}) => {
    const navigate = useNavigate();

    if(hasBack) return <div className="header-container">
    <div className="box-container custom-header-wrapper header">
        <div className="header-left">
            <AiOutlineArrowLeft size={45} onClick={() => navigate(-1)} />
            <div className="header-title">
                <div className="header-ellipsis">{storeData?.displayName}</div>
                <small className="header-address header-sub-title text-secondary">{formatOutletAddress(storeData)}
                </small>
            </div>
        </div>
    </div>
    <div className="separator separator-theme"></div>
</div>
    return <div className="header-container">
    <div className="box-container custom-header-wrapper header">
        <div className="header-title text-center">
            <div className="header-ellipsis">{storeData?.displayName}</div>
            <small className="header-address header-sub-title text-secondary">{formatOutletAddress(storeData)}
            </small>
        </div>
    </div>
    <div className="separator separator-theme"></div>
</div>
}

export default Header;