import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { apiService } from "../../services";
import CustomButton from "../custom-components/custom-button";
import { LoaderWithChildren } from "../custom-components/custom-loader";
import CustomModal from "../custom-components/custom-modal";
import MenuItemsList from "../Menu-Item-List";
import "./Menus.scss";
import LogoutModal from "./LogoutModal";

const Menus = (props) => {

    const { storeData } = props;
    const [isOpen, setIsOpen] = useState(true);
    const { storeId } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(true);
    const [menuList, setMenuList] = useState([]);
    const [structuredMenuList, setStructuredMenuList] = useState([]);
    const [selectedMenuId, setSelectedMenuId] = useState("");
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    
    const [searchParams] = useSearchParams();
    const paramMenuRef = searchParams.get("menuId");

    const bodyStyles = document.body.style;
    const backgroundColor = bodyStyles.getPropertyValue('--theme-background-color') || "#000";

    useEffect(() => {
        if (storeData && storeData.timezone) {
            setLoading(true);
            apiService.getStoreKioskMenu(storeId, { timezone: storeData.timezone, siteRef: storeData?.site?.siteRef })
                .then(response => {
                    setMenuList(response);
                    if (response && response.length) {
                        setStructuredMenuList(response.map((mnu, id) => {
                            return {
                                label: mnu?.menu?.displayName,
                                value: mnu?.menu?._id
                            }
                        }));
                        if (response.length === 1 && response[0] && response[0].menu) {
                            setSelectedMenuId(response[0]?.menu?._id)
                            navigate(`/store/${storeId}/menus?menuId=${response[0]?.menu?._id}`, { replace: true });
                        }
                    }
                    setLoading(false)
                }).catch(err => setLoading(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeData]);

    useEffect(() => {
        if (selectedMenuId !== "" && String(paramMenuRef) !== String(selectedMenuId)) {
            navigate(`${pathname}?menuId=${selectedMenuId}`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMenuId]);

    const commonHeader = (store) => {
        return (
            <>
                <div className="box-container custom-header-wrapper header">
                    <div className="header-left">
                        <div className="header-title">
                            <div className="fs-heading">{store?.displayName}</div>
                        </div>
                    </div>
                </div>
                <div className="separator separator-theme"></div>
            </>
        )
    }

    if (loading || !storeData || Object.keys(storeData).length === 0) {
        return <LoaderWithChildren>Please wait while we are loading the menu</LoaderWithChildren>
    }

    if(storeData && storeData.isRegisterOpen !== undefined && !storeData.isRegisterOpen){
        return <React.Fragment>
            {commonHeader(storeData)}
            <div className="box-container info-container">
                <div className="info-text">Sorry, we are closed and not accepting orders</div>
                <div className="sticky-footer menus-wrapper-footer">
                    <div className="logout-section">
                        <div className="logout-button-link" onClick={() => setIsLogoutModalOpen(true)}>Log Out</div>
                    </div>
                </div>
            </div>
            <LogoutModal 
                isLogoutModalOpen={isLogoutModalOpen} 
                setIsLogoutModalOpen={setIsLogoutModalOpen} 
            />
        </React.Fragment>
    }

    if(menuList && !menuList.length){
        return <React.Fragment>
            {commonHeader(storeData)}
            <div className="box-container info-container">
                <div className="info-text">Not accepting orders</div>
                <div className="sticky-footer menus-wrapper-footer">
                    <div className="logout-section">
                        <div className="logout-button-link" onClick={() => setIsLogoutModalOpen(true)}>Log Out</div>
                    </div>
                </div>
            </div>
            <LogoutModal 
                isLogoutModalOpen={isLogoutModalOpen} 
                setIsLogoutModalOpen={setIsLogoutModalOpen} 
            />
        </React.Fragment>
    }

    if (menuList && menuList.length > 1 && (!paramMenuRef || paramMenuRef === "")) {
        return (
            <React.Fragment>
                <CustomModal
                    isModalOpen={isOpen}
                    onRequestClose={setIsOpen}
                    title={`${storeData && storeData.displayName ? storeData.displayName + " - " : ""} Pick Up`}
                    hideDivider={false}
                    showHeader={true}
                    isClosable={false}
                    backgroundColor={backgroundColor}
                    headerClass="menu-header"
                >
                    <div className="menus-container">
                        <div className="row">
                            Select Menu
                        </div>
                        <div className="list-container">
                            {menuList && menuList.length > 1 &&
                                <>
                                    {menuList.map((item, index) => {
                                        return (
                                            <div key={index} className="menu-button-container">
                                                <CustomButton
                                                    label={item && item.menu && item.menu.displayName}
                                                    className="menu-button"
                                                    onClick={() => navigate(`${pathname}?menuId=${item?.menu?._id}`)}
                                                />
                                            </div>
                                        )
                                    })}
                                </>
                            }
                            <div className="sticky-footer menus-wrapper-footer">
                                <div className="logout-section">
                                    <div className="logout-button-link" onClick={() => setIsLogoutModalOpen(true)}>Log Out</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomModal>
                <LogoutModal 
                    isLogoutModalOpen={isLogoutModalOpen} 
                    setIsLogoutModalOpen={setIsLogoutModalOpen} 
                />
            </React.Fragment>
        )
    }
    return <MenuItemsList
        outlet={props.storeData}
        dropdownMenuList={structuredMenuList}
        rawMenuList={menuList}
    />
}

const mapStateToProps = (state) => {
    return {
        storeData: state.storeData,
    };
};

export default connect(mapStateToProps, null)(Menus);