import React from "react";
import ItemCard from "../Item-Card";
import "../menu-item-list.scss";

const CategoryCard = ({category, inBasketCount, vendorRef}) => {
    return (
        <React.Fragment>
            <div className="category-detail-info" id={category.categoryName.trim()}>
                <div className="category-name">{category?.categoryName.trim()}</div>
                {/* <div className="category-info-text">Buy 1 Get 1 &nbsp;<AiOutlineInfoCircle /></div> */}
            </div>
            <div className="separator separator-theme"></div>
            <div className="item-list-container">
                {category && category.items && category.items.length ? category.items.map((item, id) => {
                    return <ItemCard 
                                itemDetails={{
                                    ...item, 
                                    outletRef: vendorRef, 
                                    category: { categoryName: category?.categoryName, menuCategoryRef: category?._id }
                                }}
                                key={item._id}
                                inBasket={inBasketCount[item._id] || 0}
                            />
                }) : <p className="text-center" style={{ fontSize: "1.8rem" }}>No items available</p>}
            </div>
        </React.Fragment>
    )
}

export default CategoryCard;