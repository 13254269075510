import { updateObject } from "../actions/utility";

const getUserData = () => {
    try {
      return JSON.parse(localStorage.getItem("user"));
    } catch {
      return null;
    }
}

export const userInitialState = {
    user: getUserData(),
}

export const setUser = (state, action) => {
    localStorage.setItem("user",JSON.stringify(action.payload));
      return updateObject(state,{
        user: action.payload
    });
}