import "./checkout.scss";
import { FormattedNumber } from "react-intl";
import { forwardRef, useState } from "react";
import { connect } from "react-redux";
import { injectTipToCart } from "../../store/actions/ProductActions";
import { currencyMap } from "../../common/utils";

const TipBox = ({value, type, onClick, currency}) => {
    return(
        <div className="tip-box" onClick={onClick}>
            <FormattedNumber
                value={value}
                style={type === "fixed" ? "currency" : "percent"}
                currency={currency || "gbp"}
                maximumFractionDigits={0}
            />
        </div>
    );
}

const TipInput = ({onChange, placeholder, value}) => {
    return (<input placeholder={placeholder} value={value} onChange={(event) => onChange(event.target.value)} className="tip-input" type="number"/>);
}

export const TipSelection = forwardRef(({cart, setTipPercent, setTip, setTipType,onRequestClose, currency, injectTipToCart, vendorRef}, ref) => {
    const [otherTip, setOtherTip] = useState();
    const [otherTipFix, setOtherTipFix] = useState();

    const handleTipPercent = (val) => {
        injectTipToCart({ tip: val * 100, type: 'Percentage' })
        setTipPercent(val);
        setTipType("Percentage");
        setOtherTip('')
        onRequestClose();
    }

    const handleTipFixed = (val) => {
        injectTipToCart({ tip: val, type: 'Flat' }, vendorRef)
        setTip(parseFloat(val));
        setTipType("Flat");
        setOtherTipFix('')
        onRequestClose();
    }

    return (
        <div className="box-container tip-selection-container" ref={ref}>
            <h3>Percentage (%)</h3>
            <div className="tip-list">
                <TipBox value={0.05} type="percent" onClick={()=>handleTipPercent(0.05)} currency={currency}/>
                <TipBox value={0.1} type="percent" onClick={()=>handleTipPercent(0.1)} currency={currency}/>
                <TipBox value={0.15} type="percent" onClick={()=>handleTipPercent(0.15)} currency={currency}/>
                <TipInput placeholder={"Other (%)"} value={otherTip} onChange={(val)=> setOtherTip(val)}/>
                <div className="tip-box" onClick={() => handleTipPercent((otherTip || 0) * 0.01)}>Add</div>
            </div>
            <h3>Amount {currency ? `(${currencyMap[currency.toUpperCase()]})` : "£"}</h3>
            <div className="tip-list">
                <TipBox value={1} type="fixed" onClick={()=>handleTipFixed(1)} currency={currency}/>
                <TipBox value={2} type="fixed" onClick={()=>handleTipFixed(2)} currency={currency}/>
                <TipBox value={5} type="fixed" onClick={()=>handleTipFixed(5)} currency={currency}/>
                <TipInput placeholder={currency ? `Other (${currencyMap[currency.toUpperCase()]})` : "Other (£)"} value={otherTipFix} onChange={(val)=>setOtherTipFix(parseFloat(val))}/>
                <div className="tip-box" onClick={() => handleTipFixed(otherTipFix || 0)}>Add</div>
            </div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return {
        cart: state.cart,
        cartLoading: state.cartLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        injectTipToCart: (tip, vendorRef) => dispatch(injectTipToCart(tip, vendorRef)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(TipSelection);