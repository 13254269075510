import axios from "axios";
import jwtDecode from "jwt-decode";
import apiConstant from "../constants/apiConstants";
import EventEmmiter from "eventemitter3";
import { network } from "../common/network";

const userURI = apiConstant.userURI

class jwtService extends EventEmmiter {
    init(){
        // console.log("Inside JWT service")
        this.setInterceptors();
        this.handleAuthentication();
        window.addEventListener('storage', e => {
        // console.log(e.key === 'jwt_access_token')
            if (e.key === 'jwt_access_token') {
                window.location.reload();
            }
        });
    }

    setInterceptors = () => {
      axios.interceptors.request.use(
        async config => {
            const token = this.getAccessToken();
            if(token) {
                config.headers = { 
                  ...config.headers,
                  'Authorization': `Bearer ${token}`,
                }
            }
            return config;
        },
        error => {
          Promise.reject(error);
      });

      axios.interceptors.response.use(
          (response) => {
              return response;
          },
          async (err) => {
              const originalConfig = err.config;
              if (originalConfig && !originalConfig.url.includes(`${userURI}/login`) && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                  originalConfig._retry = true;
                  try {
                    const rs = await network(
                      `${apiConstant.userURI}/generate-auth-token`,
                      "POST",
                      {
                        requestToken: this.getRefreshToken(),
                      }
                    );

                    if(rs.data && rs.data.data.token) {
                      const { token } = rs.data.data;
                      this.setSession(token);
                    } else if (!originalConfig.__isRetryRequest) {
                      // if you ever get an unauthorized response, logout the user
                      this.emit("onAutoLogout", "Invalid Access Token!");
                    }
                    return axios(originalConfig)
                  } catch (_error) {
                    return Promise.reject(_error);
                  }
                }else if(err.response.status === 500 && originalConfig && originalConfig.url.includes(`${userURI}/generate-auth-token`) && err.response?.data?.messageCode === "TOKEN_NOT_CREATED"){
                  this.emit("onAutoLogout", "Invalid Access Token!");
                }
              }
              return Promise.reject(err);
          }
      )
    }

    handleAuthentication = () => {
      const access_token = this.getAccessToken();
      if (!access_token) {
        // NOTE: No access to any other page without the token and redirect user to login page.
        this.emit("onAutoLogout");
        return;
      }
  
      if (this.isAuthTokenValid(access_token)) {
        this.setSession(access_token);
        this.emit("onAutoLogin", true);
      } else {
        this.setSession(null, null);
        this.emit("onAutoLogout", "Access Token expired");
      }
    };

    signInWithUsernameAndPassword = (userName, password, vendorId, redirectURL) => {
        return new Promise((resolve, reject) => {
          network(
            `${userURI}/login`,
            "POST",
            {
              email: userName,
              password: password,
              vendorId,
            },
          )
            .then((response) => {
              if (response && response.data && response.success) {
                // console.log(" jwtService --> response",response);
                const userData = response.data.user;
                const token = userData.token;
                const refreshToken = response.data.refreshToken;
                delete userData.token;
                this.setSession(token, userData);
                this.setRefreshToken(refreshToken)
                // history.push({
                //   // NOTE: redirectURL temp
                //   pathname: '/customer'
                // //   pathname: redirectURL,
                // });
                resolve(userData);
              } else if (response?.errors) {
                reject(response.errors);
              } else {
                reject(response?.message);
              }
            })
            .catch((err) => {
               reject(err?.message);
            });
        });
    };

    setSession = (access_token, user = null) => {
        if (access_token) {
          localStorage.setItem("jwt_access_token", access_token);
          if (user) {
            localStorage.setItem("user", JSON.stringify(user));
          }
          axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
        } else {
          localStorage.removeItem("jwt_access_token");
          localStorage.removeItem("user");
          localStorage.removeItem("cartitems");
          delete axios.defaults.headers.common["Authorization"];
        }
    };

    setRefreshToken = (refreshToken) => {
        if (refreshToken) {
          localStorage.setItem("jwt_refresh_token", refreshToken);
        } else {
          localStorage.removeItem("jwt_refresh_token");
        }
    }

    logout = () => {
        this.setSession(null, null);
        this.setRefreshToken()
    };

    isAuthTokenValid = (access_token) => {
        if (!access_token) {
          return false;
        }
        const decoded = jwtDecode(access_token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          return false;
        } else {
          return true;
        }
    };

    logInWithToken = () => {
      const token = this.getAccessToken();
      return new Promise((resolve, reject) => {
        const userData = this.getCurrentUser();
        if (!userData) {
          reject(null);
        }
        this.setSession(token, userData);
        resolve(userData);
      });
    };

    getAccessToken = () => {
        const search = new URLSearchParams(window.location.search);
        if(search && search.get('accessToken')) {
          return search.get('accessToken');
        };
        return window.localStorage.getItem("jwt_access_token");
    };

    getRefreshToken = () => {
        return localStorage.getItem('jwt_refresh_token')
    }
    
    getCurrentUser = () => {
        return JSON.parse(String(window.localStorage.getItem("user")));
    };
}

const instance = new jwtService();

export default instance;