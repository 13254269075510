/* eslint-disable react/style-prop-object */
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { capitalize } from "../../../../common/utils";
import { CustomDropdown } from "../../../custom-components/custom-dropdown";
import { FaEdit } from 'react-icons/fa';
import { BiTrash } from "react-icons/bi";
import { useState } from "react";
import CustomModal from "../../../custom-components/custom-modal";
import ItemModifierModal from "../../../Menu-Item-List/Item-Modifier-Modal";
import { addItemsToCartV1, deleteItemFromCart } from "../../../../store/actions/ProductActions";


const CartItemCard = ({storeData, item, index, ...props}) => {

    const [enableModifiers, setEnableModifiers] = useState(false);

    function openModal() {
      setEnableModifiers(true);
    }

    function closeModal() {
        setEnableModifiers(false);
    }

    const handleQuantityChange = (option) => {
      const updatedQty = option.value - item.quantity;
      props.addItemsToCart({ ...item, quantity: updatedQty });
    }

    const getSubHeading = (item) => {
        let result = [];
        for (let i = 0; i < item.variants.length; i++) {
            if (item.variants[i].isSelected) {
                result.push(capitalize(item.variants[i].variantName || ''));
            }
        }
        for (let i = 0; i < item.assignedModifiers.length; i++) {
            let modifierName = item.assignedModifiers[i].displayName;
            // let count = 0;
            for (let j = 0; j < item.assignedModifiers[i].modifiers.length; j++) {
                if (item.assignedModifiers[i].modifiers[j].isSelected) {
                    // count += 1;
                    // if (count === 1) {
                    //     modifierName += " - ";
                    // } else {
                    //     modifierName += ", "
                    // }
                    result.push(`${modifierName} - ${item.assignedModifiers[i].modifiers[j].displayName + " x " + item.assignedModifiers[i].modifiers[j].quantity}`);
                }
            }
            // if (count) {
                // if (result) {
                    // result += "; ";
                // }
                // result += modifierName;
            // }
        }
        if (result && result.length) return <div className="item-description secondary-text-color"><ul className="m-0 modifier-list-item">{result.map(v => <li>{v}</li>)}</ul></div>;
        return null;
    }

    return (
        <div className="item-container">
            <div className="item-name-price">
                <div className="item-name">
                    {item.itemName}
                </div>
                <div className="item-price">
                    <FormattedNumber
                        style="currency"
                        value={item.subTotal}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        currency={storeData.currency || "gbp"}
                    />
                </div>
            </div>
            {getSubHeading(item)}
            <div className="item-footer">
                <div className="item-extra-info">
                    <CustomDropdown
                        options={[...Array(99)].map((_, index) => ({ label: index + 1, value: index + 1 }))}
                        onSelect={handleQuantityChange}
                        value={item.quantity}
                        dropDownContainerClass="custom-qty-select-container"
                        dropDownHeaderClass="custom-qty-select-header"
                        dropDownListContainerClass="custom-qty-select-list-container"
                        dropDownListClass="custom-qty-select-list"
                        dropDownItemClass="custom-qty-select-item"
                    />
                    <div style={{ marginLeft: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}><FaEdit size={35} onClick={openModal} /></div>
                </div>
                <div className="item-count">
                    <BiTrash size={45} color="red" onClick={() => {props.deleteItemFromCart(index)}} />
                </div>
            </div>
            <CustomModal
                isModalOpen={enableModifiers}
                onRequestClose={closeModal}
                hideDivider={true}
                title={item.itemName}
                isClosable={true}
                modalContainerClass={"modifier-modal-class"}
            >
                <ItemModifierModal itemData={item} closeModal={closeModal} isEdit={true} itemIndex={index} />
            </CustomModal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      storeData: state.storeData,
    };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addItemsToCart: (item) => dispatch(addItemsToCartV1(item)),
    deleteItemFromCart: (index) => dispatch(deleteItemFromCart(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartItemCard);
