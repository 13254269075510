import React, { useEffect, useState } from "react";
import "./login.scss";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { apiService } from "../../services";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../custom-components/custom-loader";
import { getImageUrl } from "../../common/utils";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import CustomButton from "../custom-components/custom-button";
import jwtService from "../../services/jwtService";
import { connect } from "react-redux";
import { setUser } from "../../store/actions/UserActions";
import { setErrorMessage } from "../../store/actions/StoreActions";

const validationSchema = yup.object().shape({
    userName: yup.string().trim().required().label("Username"),
    password: yup.string().required().label("Password")
});

const UserLogin = (props) => {
    const navigate = useNavigate();
    const { storeId } = useParams();

    const [loading, setLoading] = useState(false);
    const [outlet, setOutlet] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);

    const redirectURL = `/store/${storeId}/menus`;

    const initialValues = {
        userName: "",
        password: "",
    };

    useEffect(() => {
        setPageLoading(true);
        apiService.getStoreDetails(storeId).then(res => {
            setOutlet(res);
            setPageLoading(false);
        }).catch(err => {
            props.setErrorMessage(err.message)
            setPageLoading(false);
            // navigate('/404', { replace: true })
        })
        //eslint-disable-next-line
    }, [storeId])

    const onSubmit = async (values) => {
        setLoading(true);
        await jwtService.signInWithUsernameAndPassword(values.userName, values.password, storeId, redirectURL)
            .then(async (user) => {
                props.setUser(user);
                if (outlet && (!outlet.inPersonMerchantServiceAccountRef || outlet.inPersonPaymentGateway !== 'adyen' || !((outlet.adyen && outlet.adyen.storeId) || (outlet.adyen && outlet.adyen.adyenChild && outlet.adyen.adyenChild.storeId)))) {
                    props.setErrorMessage("Payment details not found!")
                }
                navigate(`/store/${storeId}/menus`, { replace: true });
                setLoading(false);
            }).catch((error) => {
                props.setErrorMessage(error);
                props.setUser(null);
                setLoading(false);
            });
    }

    if (pageLoading) {
        return <Loader />
    }

    return (
        <React.Fragment>
            <div className="main-container login">
                <div className="flex-col-container">
                    <div className="flex-row-justify-center store-title">{outlet?.displayName}</div>
                    {(outlet?.outletImage || outlet?.mapImage) ? <div className="banner">
                        {outlet?.outletImage && <div className="banner-top">
                            <img src={getImageUrl(outlet.outletImage)} alt="Logo not found" />
                        </div>}
                        {outlet?.mapImage && <div className="banner-main">
                            <img src={getImageUrl(outlet.mapImage)} alt="Logo not found" />
                        </div>}
                    </div> : null}
                    <div className="form-wrapper">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {(props) => (
                                <div className="form-container">
                                    <div className="form-item-container">
                                        <div className="form-item">
                                            <label className="form-label">Username</label>
                                            <input
                                                {...props.getFieldProps("userName")}
                                                name="userName"
                                                type="text"
                                                placeholder="Insert Username"
                                                className="form-input"
                                                autoComplete="new-password"
                                            />
                                            <ErrorMessage name="userName" component="div" className="form-error" />
                                        </div>
                                    </div>
                                    <div className="form-item-container">
                                        <div className="form-item">
                                            <label className="form-label">Password</label>
                                            <div className="password-form-group">
                                                {showPassword ?
                                                    <AiFillEye className="icon" onClick={() => setShowPassword(!showPassword)} size={30} /> :
                                                    <AiFillEyeInvisible className="icon" onClick={() => setShowPassword(!showPassword)} size={30} />
                                                }
                                                <input
                                                    {...props.getFieldProps("password")}
                                                    name="password"
                                                    type={showPassword ? "text" : "password"}
                                                    placeholder="Insert Password"
                                                    className="form-input"
                                                    autoComplete="new-password"
                                                />
                                            </div>
                                            <ErrorMessage name="password" component="div" className="form-error" />
                                        </div>
                                    </div>
                                    <div className="form-item-container">
                                        <div className="form-item">
                                            <CustomButton
                                                type="submit"
                                                onClick={props.handleSubmit}
                                                label="Sign In"
                                                loader={loading}
                                                disabled={loading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => {
            dispatch(setUser(user));
        },
        setErrorMessage: (message) => dispatch(setErrorMessage(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);
