import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import jwtService from "../../services/jwtService";
import { setUser } from "../../store/actions/UserActions";

const Authentication = ({children}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

   const splittedString = location.pathname.replace("/store/", "").split("/");
   const storeId = splittedString[0];

    useEffect(() => {
        jwtCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    function jwtCheck(){
        jwtService.on("onAutoLogin", () => {
            jwtService.logInWithToken().then((user) => {
                dispatch(setUser(user));
                if (`/store/${storeId}` === location.pathname){
                    navigate(`/store/${storeId}/menus`, { replace: true })
                }else{
                    let pathUrl = location.pathname;
                    if(location.search) pathUrl += location.search;
                    navigate(pathUrl, { replace: true });
                }
            }).catch(() => {
                navigate(`/store/${storeId}`, { replace: true })
            });
        })

        jwtService.on("onAutoLogout", () => {
            navigate(`/store/${storeId}`, { replace: true });
            jwtService.logout();
        });

        jwtService.init();
    }
    return <React.Fragment>{children}</React.Fragment>
}



export default Authentication;