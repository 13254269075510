/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import { AiOutlineInfoCircle, AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { getImageUrl, observerShowDescription, useDebounce } from "../../../common/utils";
import { addItemsToCartV1, setQuantityLoader } from "../../../store/actions/ProductActions";
import CustomModal from "../../custom-components/custom-modal";
import CustomAttributesModal from "../Custom-Attributes-Modal";
import ItemModifierModal from "../Item-Modifier-Modal";
import "../menu-item-list.scss";

const ItemCard = ({itemDetails, storeData, setQuantityLoader, inBasket, basket, ...props}) => {

    const bodyStyles = document.body.style;
    const backgroundColor = bodyStyles.getPropertyValue('--theme-background-color');

    const [enableModifiers, setEnableModifiers] = useState(false);
    const [diff, setDiff] = useState(0);
    const [showCustomAttributeModal, setShowCustomAttributeModal] = useState(false);

    useEffect(() => {
        observerShowDescription();
    }, [])

    useEffect(() => {
        if (diff) {
            handleQuantityChange();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diff]);

    const handleQuantityChange = useDebounce(() => {
        setQuantityLoader(true);
        props.addItemsToCart({ ...itemDetails, quantity: diff })
        setDiff(0);
        setQuantityLoader(false);
    }, 300);


    function closeModal() {
        setEnableModifiers(false);
    }

    function openModal() {
        setEnableModifiers(true);
    }

    const handlePlusClick = () => {
        const d = diff + 1;
        if (itemDetails?.assignedModifiers?.length || itemDetails?.variants?.length) {
            openModal();
        } else {
            setDiff(d);
        }
    };

    const handleMinusClick = () => {
        setDiff(diff - 1);
    }

    function getItemDiet(diet) {
        let res = "";
        if (typeof (diet) === "string") {
            return diet;
        }
        for (let i = 0; i < diet.length; i++) {
            if (res) {
                res += `, `;
            }
            res += diet[i];
        }
        return res;
    }

    let modifiersItemsData = JSON.parse(JSON.stringify(itemDetails));
    // eslint-disable-next-line eqeqeq
    const basketItemIndex = basket && basket.items && basket.items.length && basket.items.findIndex((ind) => ind.assignedMenuItemRef == itemDetails._id)

    return (
        <>
            <div className="item-detail-card">
                <div className="item-title-section" onClick={openModal}>
                    <div className="item-title">{itemDetails.itemDisplayName}</div>
                    <div className="item-price">
                        {itemDetails.assignedModifiers.length || itemDetails?.variants?.length ? "From " : ""}
                        <FormattedNumber
                            style="currency"
                            value={itemDetails.itemPrice}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={storeData?.currency || "gbp"}
                        />
                    </div>
                </div>
                <div className="item-other-details">
                    {itemDetails?.itemImage &&
                    <div className="item-image">
                        <img src={getImageUrl(itemDetails?.itemImage)} alt="Display not found" />
                    </div>}
                    <div className="item-description-section">
                        <div className="item-description text-secondary p-2" onClick={openModal}>
                            <p className="m-0">{itemDetails?.description}</p>
                            <label>View More</label>
                        </div>
                        <div className="item-footer">
                            <div className="item-extra-info">
                                {((itemDetails.customAttributes && 
                                    itemDetails.customAttributes.length) || (
                                        itemDetails.nutritions &&
                                        Object.values(itemDetails.nutritions).some(
                                            (val) => val !== 0,
                                        ))) ?
                                    <span className="item-tag item-details-link" onClick={() => setShowCustomAttributeModal(true)}>
                                        <AiOutlineInfoCircle /> Item details
                                    </span> : null
                                }
                            </div>
                            <>
                                {<div style={{ position: "relative" }}>
                                    <div className={`item-count ${props.quantityLoader ? 'disable-qty-btn' : ''}`}>
                                        {Boolean((inBasket + diff) > 0) && itemDetails.assignedModifiers.length === 0 && itemDetails?.variants?.length === 0 && 
                                            <AiOutlineMinusSquare color={backgroundColor} size={65} onClick={handleMinusClick} />
                                        }
                                        {Boolean((inBasket + diff) > 0) && 
                                            <span className="item-quantity">{inBasket + diff}</span>
                                        } 
                                        <AiOutlinePlusSquare color={backgroundColor} size={65} onClick={handlePlusClick} />
                                    </div>
                                        {/* {props.quantityLoader && <QuantityLoader />} */}
                                </div>}
                            </>
                        </div>
                    </div>
                </div>
            </div>
            <CustomModal
                isModalOpen={enableModifiers}
                onRequestClose={closeModal}
                hideDivider={true}
                title={itemDetails.itemDisplayName}
                modalContainerClass={"modifier-modal-class"}
                isClosable={true}
            >
                {(basketItemIndex >= 0 && Boolean(inBasket + diff) && itemDetails.assignedModifiers.length === 0 && itemDetails.variants.length === 0) ? 
                    <ItemModifierModal 
                        itemData={{
                            ...modifiersItemsData,
                            quantity: inBasket,
                            ...(basket.items || [])[basketItemIndex],
                            specialInstructions: ((basket.items || [])[basketItemIndex]?.specialInstructions || ""),
                        }} 
                        closeModal={closeModal} 
                        isEdit={true} 
                        itemIndex={basketItemIndex}
                    /> : 
                    <ItemModifierModal 
                        enableModifiers={enableModifiers} 
                        itemData={modifiersItemsData} 
                        closeModal={closeModal}  
                    /> 
                }
            </CustomModal> 
            <CustomAttributesModal
                showCustomAttributeModal={showCustomAttributeModal}
                setShowCustomAttributeModal={setShowCustomAttributeModal}
                itemData={itemDetails}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
      storeData: state.storeData,
      basket: state.cart,
      quantityLoader: state.quantityLoader,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addItemsToCart: (item) => dispatch(addItemsToCartV1(item)),
        setQuantityLoader: (value) => {
            return dispatch(setQuantityLoader(value))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCard);
