/* eslint-disable react/style-prop-object */
import ActionSheet from "actionsheet-react"
import React, { useRef, useState } from "react"
import { AiOutlineClose } from "react-icons/ai"
import { FormattedNumber } from "react-intl"
import { connect } from "react-redux"
import { toggleActionSheet } from "../../common/utils"
import { injectTipToCart } from "../../store/actions/ProductActions"
import CustomButton from "../custom-components/custom-button"
import TipSelection from "./TipSelection"
const actionSheetStyle = { borderRadius: 0, margin: "auto", left: 0, };

const SingleOrderSummaryInfo = React.forwardRef(({
    basket = {},
    storeUser,
    storeData,
    injectTipToCart
}, _) => {

    const tipRef = useRef();
    const tipSectionRef = useRef();
    const [tip, setTip] = useState(basket.tipAmount || 0);
    // eslint-disable-next-line no-unused-vars
    const [tipType, setTipType] = useState(basket && basket.tipType ? basket.tipType : storeUser.preSetTipType);

    const setTipPercent = (val) => {
        setTip(basket?.subTotal * val);
    }

    const handleTipChange = () => {
        if (tip) {
            injectTipToCart(null).then(() => setTip(0))
        } else {
            toggleActionSheet(tipRef, true);
        }
    }

    return <>
        <div className="checkout-order-summary-sub-section">
            <div className="d-flex justify-content-between indent-left-30px">
                <span className="text-bold">Subtotal</span>
                <div className="flex-end text-bold">
                    <FormattedNumber
                        style="currency"
                        value={basket.subTotal}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        currency={storeData.currency || "gbp"}
                    />
                </div>
            </div>
            {Boolean(basket.serviceCharge) &&
                <div className="d-flex justify-content-between mt-rem-2 indent-left-30px">
                    <span className="text-secondary">Service Charge</span>
                    <div className="flex-end text-secondary">
                        <FormattedNumber
                            style="currency"
                            value={basket.serviceCharge}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={storeData.currency || "gbp"}
                        />
                    </div>
                </div>
            }

            <div className="d-flex justify-content-between align-items-center mt-rem-1 indent-left-30px" style={{ marginBottom: ".5rem" }}>
                <span className="text-bold">Tip</span>
                <div className="d-flex justify-content-between align-items-center flex-end w-50">
                    <div className="flex-start tip-action-title">
                        <CustomButton
                            className={`btn-link p-0 mr-5 ${tip ? "text-danger" : ""}`}
                            onClick={handleTipChange}
                        >
                            {tip ? "Remove" : "Add Tip"}
                        </CustomButton>
                    </div>
                    <div className="flex-end text-bold">
                        <FormattedNumber
                            style="currency"
                            value={tip}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={storeData.currency || "gbp"}
                        />
                    </div>
                </div>
            </div>
            {basket.exclusiveTaxTotal > 0 &&
                <div className="d-flex justify-content-between mt-rem-2 indent-left-30px">
                    <span className="text-secondary">Exclusive taxes</span>
                    <div className="flex-end text-secondary">
                        <FormattedNumber
                            style="currency"
                            value={basket.exclusiveTaxTotal}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={storeData.currency || "gbp"}
                        />
                    </div>
                </div>
            }
            <div className="order-detail-separator"></div>
            <div className="d-flex justify-content-between mt-rem-1 indent-left-30px">
                <span className="column-two text-bold">Total</span>
                <div className="flex-end text-bold">
                    <FormattedNumber
                        style="currency"
                        value={basket.totalAmount}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        currency={storeData.currency || "gbp"}
                    />
                </div>
            </div>
            {basket.inclusiveTaxTotal > 0 &&
                <div className="d-flex justify-content-between mt-rem-2 indent-left-30px">
                    <span className="text-secondary">Inclusive taxes</span>
                    <div className="flex-end text-secondary">
                        <FormattedNumber
                            style="currency"
                            value={basket.inclusiveTaxTotal}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={storeData.currency || "gbp"}
                        />
                    </div>
                </div>
            }
        </div>
        <ActionSheet ref={tipRef} sheetStyle={actionSheetStyle}>
            <div className="payment-section-wrapper">
                <div className="action-header d-flex justify-content-between align-items-center">
                    <h2 className="mt-0">Staff Tip</h2>
                    <AiOutlineClose onClick={() => toggleActionSheet(tipRef)} size={40} />
                </div>
                <TipSelection ref={tipSectionRef} setTip={setTip} setTipPercent={setTipPercent} setTipType={setTipType} onRequestClose={() => toggleActionSheet(tipRef)} vendorRef={basket.vendorRef} currency={storeData?.currency || "gbp"} />
            </div>
        </ActionSheet>
    </>

});


const mapStateToProps = (state) => {
    return {
        storeData: state.storeData,
        storeUser: state.user,
        basket: state.cart,
    };
};

const mapDispatchToPros = (dispatch) => {
    return {
        injectTipToCart: (tip) => dispatch(injectTipToCart(tip)),
    }
}

export default connect(mapStateToProps, mapDispatchToPros)(SingleOrderSummaryInfo);