import React from "react";
import "./custom-checkbox.scss";

const CustomCheckbox = ({containerClass, isChecked, label, type="box", handleClick}) => {
    return (
        <div className={`checkbox-container ${containerClass}`} onClick={handleClick}>
            <div className={`${type}-${isChecked ? "checked" : "unchecked"}-radio`}>
                <div className={`${type}-${isChecked ? "checked" : "unchecked"}-radio-outline`}>
                    <div className={`${type}-${isChecked ? "checked" : "unchecked"}-radio-inner`}></div>
                </div>
            </div>
            {label && <div className="checkbox-label">{label}</div>}
        </div>
    )
}

export default CustomCheckbox;