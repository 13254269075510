import CustomModal from "../../custom-components/custom-modal";

const NutritionMapping = {
    energy: { title: "Energy", unit: "kcal" },
    protein: {title: "Proteins", unit: "Grams"},
    carbohydrate: {title: "Carbohydrates", unit: "Grams"},
    fat: {title: "Fat", unit: "Grams"},
    saturatedFat: {title: "Saturated fat", unit: "Grams"},
}

const CustomAttributesModal = (props) => {
    const { showCustomAttributeModal, setShowCustomAttributeModal, itemData } = props;
    const customAttributeData = itemData?.customAttributes || [];
    return (
        <CustomModal
            isModalOpen={showCustomAttributeModal}
            onRequestClose={() => setShowCustomAttributeModal(false)}
            ariaHideApp={false}
            hideDivider={true}
            modalContainerClass={"custom-attribute-modal-container"}
            isClosable={true}
            title={`${itemData?.itemName || "Item"} details`}
        >
            <div>
                <div className="header-divider"></div>
                <div className="custom-attribute-modal-content">
                    {(customAttributeData || []).filter((attr) => attr && attr.attributeValues && attr.attributeValues.length > 0)
                        .map((attribute, index) => {
                            const allAttributeValues = attribute.attributeValues.map((value) => value?.name)
                            return (
                                <div className="attribute-block" key={index}>
                                    <div className="attribute-title">{attribute.attributeName}</div>
                                    <div className="attribute-values">{allAttributeValues.join(", ")}</div>
                                </div>
                            )
                    })}

                    {itemData.nutritions &&
                        Object.values(itemData.nutritions).some(
                            (val) => val !== 0
                        ) && (
                        <div className="nutrition-block">
                            <div className="nutrition-title">
                                Nutrition
                            </div>
                            {Object.entries(itemData.nutritions)?.map(([nutritionKey, value]) => {
                                if(value === 0) return <></>;
                                return (
                                    <div className="nutrition-attribute-container">
                                        <div className="nutrition-name">{NutritionMapping[nutritionKey].title}</div>
                                        <div className="nutrition-unit flex-row-end">{NutritionMapping[nutritionKey].unit}</div>
                                        <div className="nutrition-value flex-row-end">{value}</div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
        </CustomModal>
    )
}

export default CustomAttributesModal;