/* eslint-disable react/style-prop-object */
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { apiService } from "../../services";
import CustomButton from "../custom-components/custom-button";
import { Loader } from "../custom-components/custom-loader";
import "./order-detail.scss";
import "../Checkout/checkout.scss";
import { FormattedNumber } from "react-intl";
import { setErrorMessage } from "../../store/actions/StoreActions";
const renderFullName = (orderDetails) => {
    let name = "";
    if (orderDetails && orderDetails.user && orderDetails.user.firstName) {
        name += orderDetails.user.firstName
    }
    if (orderDetails && orderDetails.user && orderDetails.user.lastName) {
        name += ` ${orderDetails.user.lastName}`
    }
    return name;
}
const OrderDetail = (props) => {
    const { storeId, orderId } = useParams();
    const navigate = useNavigate();

    const mountedRef = useRef(false);
    let timer;
    let timerOut;
    const [loading, setLoading] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});
    const [count, setCount] = useState(20);

    useEffect(() => {
        mountedRef.current = true;
        fetchOrderDetails();
        updateCount();
        return () => {
            mountedRef.current = false;
            clearInterval(timer);
            clearTimeout(timerOut);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateCount = () => {
        timer = !timer && setInterval(() => {
            if (count > 0) {
                setCount(prevCount => prevCount - 1);
            }
        }, 1000);

        timerOut = !timerOut && setTimeout(() => {
            navigate(`/store/${storeId}/menus`, { replace: true });
        }, 20000)
    }


    const fetchOrderDetails = () => {
        setLoading(true);
        apiService.getOrderDetail(storeId, orderId)
        .then(resp => {
            if(mountedRef.current){
                setOrderDetails(resp);
                setLoading(false);
            }
        }).catch(err => {
            if(mountedRef.current){
                props.setErrorMessage(err?.message || "");
                setLoading(false);
            }
        })
    }


    if (loading) {
        return <Loader />
    }

    return (
        <>
            <div className="box-container order-detail-container">
                {orderDetails && Object.keys(orderDetails).length > 0 ?
                    <React.Fragment>
                        <div className="order-detail-header">
                            <div className="store-info">
                                <div className="store-title">{orderDetails && orderDetails.vendor && orderDetails.vendor.displayName ? orderDetails.vendor.displayName : ""}</div>
                                <div className="store-address text-secondary">{orderDetails && orderDetails.vendor && orderDetails.vendor.landmark ? orderDetails.vendor.landmark : ""}</div>
                            </div>
                            <div className="box-container order-info">
                                <div className="order-status">Order Confirmed</div>
                                <div className="order-page-duration">This page will close in: {count} seconds</div>
                            </div>
                        </div>
                        <div className="order-detail-content">
                            <div className="customer-info-section">
                                <div className="customer-info-title">Your Details</div>
                                <div className="customer-info-sub-section">
                                    <div className="customer-info-sub-label text-secondary">Name - (Used to call you once order is ready)</div>
                                    <div className="customer-info-sub-value">{renderFullName(orderDetails)}</div>
                                </div>
                                {orderDetails && orderDetails.user && orderDetails.user.email &&
                                    <div className="customer-info-sub-section">
                                        <div className="customer-info-sub-label text-secondary">Email</div>
                                        <div className="customer-info-sub-value">{orderDetails.user.email}</div>
                                    </div>
                                }
                                {orderDetails && orderDetails.note &&
                                    <div className="customer-info-sub-section" style={{ marginBottom: "30px" }}>
                                        <div className="customer-info-sub-label text-secondary">Note</div>
                                        <div className="customer-info-sub-value">{orderDetails.note}</div>
                                    </div>
                                }
                            </div>
                            <div className="separator separator-theme"></div>
                            <div className="order-summary-title">Order Summary</div>
                            <div className="order-summary-sub-section">
                                <div className="d-flex justify-content-between indent-left-30px">
                                    <span className="text-bold">Subtotal</span>
                                    <div className="flex-end text-bold">
                                        <FormattedNumber
                                            style="currency"
                                            value={orderDetails.subTotal}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            currency={orderDetails.currency || "gbp"}
                                        />
                                    </div>
                                </div>
                                {Boolean(orderDetails.serviceCharge) &&
                                    <div className="d-flex justify-content-between mt-rem-2 indent-left-30px">
                                        <span className="text-secondary">Service Charge</span>
                                        <div className="flex-end text-secondary">
                                            <FormattedNumber
                                                style="currency"
                                                value={orderDetails.serviceCharge}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                                currency={orderDetails.currency || "gbp"}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="d-flex justify-content-between mt-rem-2 indent-left-30px">
                                    <span className="text-bold">Tip</span>
                                    <div className="flex-end text-bold">
                                        <FormattedNumber
                                            style="currency"
                                            value={orderDetails.tipAmount}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            currency={orderDetails.currency || "gbp"}
                                        />
                                    </div>
                                </div>

                                {orderDetails.exclusiveTaxTotal > 0 &&
                                    <div className="d-flex justify-content-between mt-rem-2 indent-left-30px">
                                        <span className="text-secondary">Exclusive taxes</span>
                                        <div className="flex-end text-secondary">
                                            <FormattedNumber
                                                style="currency"
                                                value={orderDetails.exclusiveTaxTotal}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                                currency={orderDetails.currency || "gbp"}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="separator total-separator mt-rem-2"></div>
                                <div className="d-flex justify-content-between mt-rem-1 indent-left-30px">
                                    <span className="column-two text-bold">Total</span>
                                    <div className="flex-end text-bold">
                                        <FormattedNumber
                                            style="currency"
                                            value={orderDetails.totalAmount}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            currency={orderDetails.currency || "gbp"}
                                        />
                                    </div>
                                </div>
                                {orderDetails.inclusiveTaxTotal > 0 &&
                                    <div className="d-flex justify-content-between mt-rem-2 indent-left-30px">
                                        <span className="text-secondary">Inclusive taxes</span>
                                        <div className="flex-end text-secondary">
                                            <FormattedNumber
                                                style="currency"
                                                value={orderDetails.inclusiveTaxTotal}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                                currency={orderDetails.currency || "gbp"}
                                            />
                                        </div>
                                    </div>
                                }
                                {[
                                    { label: "VAT (5%)", value: "vatAt5" },
                                    { label: "VAT (12.5%)", value: "vatAt12_5" },
                                    { label: "VAT (20%)", value: "vatAt20" },
                                ].map((vat, index) => {
                                    return (
                                        <div key={index} className="indent-left-30px">
                                            {orderDetails[vat.value] !== undefined && orderDetails[vat.value] !== 0 &&
                                                <div className="d-flex justify-content-between mt-rem-1 text-secondary">
                                                    <span className="column-two text-secondary">{vat.label}</span>
                                                    <div className="flex-end text-secondary">
                                                        <FormattedNumber
                                                            style="currency"
                                                            value={orderDetails[vat.value]}
                                                            minimumFractionDigits={2}
                                                            maximumFractionDigits={2}
                                                            currency={orderDetails.currency || "gbp"}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </React.Fragment> :
                    null}
            </div>
            <div className="sticky-footer order-detail-bottom-fixed">
                <div className="separator separator-theme"></div>
                <div className="box-container new-order-button-container">
                    <CustomButton className="btn-block" onClick={() => navigate(`/store/${storeId}/menus`, { replace: true })}>
                        New Order
                    </CustomButton>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        storeData: state.storeData,
        basket: state.cart,
        serviceType: 'kiosk'
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setErrorMessage: (message) => dispatch(setErrorMessage(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);