import { useEffect, useRef } from "react";
import { toast } from "react-toastify";

export function notify(error) {
  return toast.error(error, { position: "top-center" });
}

export const getImageUrl = (image, isBackground = false) => {
  if (image) return `${process.env.REACT_APP_IMAGE_URL}/${image}`;
  // if (isBackground) return defaultImage;
};

export const createArray = (arr, boolArr) => {
  let res = [];
  for (let i = 0; i < boolArr.length; i++) {
    if (boolArr[i]) {
      res.push(arr[i]);
    }
  }
  return res;
};

export const useDebounce = (callback, delay) => {
  const latestCallback = useRef();
  const latestTimeout = useRef();

  useEffect(() => {
    latestCallback.current = callback;
  }, [callback]);

  return () => {
    if (latestTimeout.current) {
      clearTimeout(latestTimeout.current);
    }

    latestTimeout.current = setTimeout(() => {
      latestCallback.current();
    }, delay);
  };
};

export const capitalize = (str = "") =>
  str.charAt(0).toUpperCase() + str.slice(1);

export function restructureMenu(menu) {
  menu.forEach((category) => {
    category.items.sort((itemA, itemB) => {
      return itemA.displayOrder - itemB.displayOrder;
    });
  });
  return menu.map((cat) => {
    return {
      ...cat,
      items: cat.items.map((item) => {
        let tempItem = {
          ...item.item,
          ...item,
          price: item.itemPrice,
          assignedModifiers:
            item.assignedModifiers && item.assignedModifiers.length
              ? item.assignedModifiers.map((modifier) =>
                  rearrangeModifier(modifier)
                )
              : [],
          variants: item.item.variants ? item.item.variants : [],
          subTotal: item.itemPrice,
          category: {
            menuCategoryRef: item.menuCategoryRef,
            categoryName: cat.categoryName,
          },
          itemName: item.itemDisplayName,
          menu: {...item.menuRefData},
          menuGroup: {
            menuGroupRef: cat.menuGroupRef,
          },
          printerRef:
            item.printer && item.printer.length
              ? item.printer[0].printerRef
              : null,
          assignedPrinters:
            item.printer && item.printer.length
                ? item.printer[0].assignedPrinters || []
                : [],
          kdsRef: item.kds && item.kds.length ? item.kds[0].kdsRef : null,
          kdsGroupRef:
            item.kds && item.kds.length ? item.kds[0].kdsGroupRef : null,
        };
        delete tempItem.item;
        delete tempItem.menuRefData;
        return tempItem;
      }),
    };
  });
}

function rearrangeModifier(modifier) {
  let tempMod = {
    ...modifier.modifierGroup,
    ...modifier,
  };
  delete tempMod.modifierGroup;
  return tempMod;
}

export const observerShowDescription = (tag = "p") => {
  const ps = document.querySelectorAll(tag);
  const observer = new ResizeObserver((entries) => {
    for (let entry of entries) {
      entry.target.classList[
        entry.target.scrollHeight > entry.contentRect.height ? "add" : "remove"
      ]("truncated");
    }
  }, []);

  ps.forEach((p) => {
    observer.observe(p);
  });
};

export const getServiceFee = (amount, outlet, user) => {
  // if (!outlet || !outlet.settings[serviceType]) return 0;
  // const service = outlet.settings[serviceType];
  let serviceFee = 0;
  if (user.serviceChargeCutOff && amount >= user.serviceChargeCutOff) {
    return +serviceFee.toFixed(2);
  }
  if (user.serviceChargeType === "Percentage") {
    serviceFee = (user?.serviceChargeAmount * amount) / 100;
  } else if (user.serviceChargeType === "Flat") {
    serviceFee = user?.serviceChargeAmount;
  }
  // if (user.serviceChargeCutOff && serviceFee > user.serviceChargeCutOff) {
  //   return +user.serviceChargeCutOff.toFixed(2);
  // }
  return +serviceFee.toFixed(2);
};

export function getInBasketCount(basket) {
  var countObject = {};

  for (
    var i = 0;
    basket && basket.items && basket.items.length && i < basket.items.length;
    i++
  ) {
    if (
      basket.items[i] &&
      basket.items[i].assignedRef &&
      countObject[basket.items[i].assignedRef]
    ) {
      countObject[basket.items[i].assignedRef] += basket.items[i].quantity;
    } else {
      countObject[basket.items[i].assignedRef] = basket.items[i].quantity;
    }
  }
  return countObject;
}

export const basketToRequest = (basket, serviceData) => {
  let newCart = {
    ...basket,
    ...serviceData,
    currency: basket.currency || "gbp",
    type: "WEB",
  };
  newCart.items = (newCart.items || []).map((oldItem) => {
    let item = {
      ...oldItem,
      itemImage: oldItem.imageUrl,
      price: oldItem.itemPrice,
      itemType: "item",
    };

    for (let i = 0; i < item.variants.length; i++) {
      if (item.variants[i].isSelected) {
        item.variant = {
          ...item.variants[i],
          price: item.variants[i].variantSellingPrice,
          sku: item.variants[i].variantSKU,
          name: item.variants[i].variantName
        };
        break;
      }
    }
    delete item.variants;
    delete item.assignedModifiers;
    delete item.imageUrl;
    return item;
  });
  delete newCart.menuRef;
  return newCart;
};

export const toggleActionSheet = (ref, isOpen = false) => {
  if (isOpen) ref.current.open();
  else ref.current.close();
};

export const createCheckoutRequestBody = (
  basket,
  storeId,
  serviceData,
  checkoutDetails,
  extraData
) => {
  let storeUser = null;
  try {
    storeUser = JSON.parse(localStorage.getItem("user")) || {};
  } catch (err) {
    console.log("User not found");
  }
  const cart = basketToRequest(basket, serviceData);
  return {
    cart: {
      ...cart,
      ...mapAdditionalKey(basket, checkoutDetails, extraData),
      type: "KIOSK",
    },
    serviceType: basket?.serviceType || "kiosk",
    serviceTypeDisplayName: basket?.serviceTypeDisplayName,
    newsLetter: extraData && extraData.marketing ? extraData.marketing : false,
    paymentType: "CARD",
    paymentGateway: "adyen",
    adyen: {
      paymentType: "cloud",
      poiid: storeUser?.terminalId,
      saleId: "128369134",
    },
    type: "KIOSK",
  };
};

export const mapAdditionalKey = (basket, checkoutDetails, extras = {}) => {
  return {
    tip: {
      value: basket && basket.tip ? basket.tip : 0,
      amount: basket && basket.tipAmount ? basket.tipAmount : 0,
      type: basket && basket.tipType ? basket.tipType : "",
    },
    counterDineType: checkoutDetails.deliveryType,
    subTotal: basket.subTotal,
    totalAmount: basket.totalAmount,
    serviceCharge: basket.serviceCharge,
    kioskUserId: basket.kioskUserId,
    totalAmountAfterDiscount: 0,
    currency: basket.currency || "gbp",
    tipAmount: basket.tipAmount,
    note: checkoutDetails.note,
    user: {
      email: checkoutDetails.email,
      firstName: checkoutDetails.name,
      lastName: checkoutDetails.lastName,
    },
    paymentGateway: "adyen",
  };
};

export const genrateUniqueId = () => {
  const dateString = Date.now().toString(36);
  const randomness = Math.random().toString(36).substr(2);
  return dateString + randomness;
};

export const currencyMap = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: '$',
  BTC: '₿',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BYR: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: '$',
  CNH: '¥',
  CNY: '¥',
  COP: '$',
  COU: 'COU',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: 'S$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYI: 'UYI',
  UYU: '$U',
  UYW: 'UYW',
  UZS: 'лв',
  VEF: 'Bs',
  VES: 'Bs.S',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  XSU: 'Sucre',
  XUA: 'XUA',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWD: 'Z$',
  ZWL: '$'
}

export const getAllMergedCustomAttributes = (menu) => {
  menu.forEach((category) => {
      category.items.sort((itemA, itemB) => {
          return itemA.displayOrder - itemB.displayOrder;
      });
  });

  let dynamicCustomAttributes: any = {};

  menu.forEach((category) => {
      if(category && category.items && category.items.length){
          category.items.forEach((item) => {
              if(item.item.customAttributes && item.item.customAttributes.length){
                  return item.item.customAttributes.forEach((attribute) => {
                      if(attribute.customAttributeRef && attribute.attributeValues && attribute.attributeValues.length){
                          const validCustomAttributes = attribute.attributeValues.filter((attr) => attr?.name?.trim() !== "" || attr?.name !== "" );
                          if(!dynamicCustomAttributes[attribute.customAttributeRef]){
                              const tempAttributeValues = attribute.attributeValues.filter((attr) => attr?.name?.trim() !== "" || attr?.name !== "" )?.map((attrVal) => attrVal?.name);
                              dynamicCustomAttributes[attribute.customAttributeRef] = {
                                  attributeName: attribute.attributeName,
                                  attributeValues: tempAttributeValues,
                                  checkedValues: new Array(tempAttributeValues.length).fill(false),
                                  customAttributeRef: attribute.customAttributeRef,
                                  attributeObjects: validCustomAttributes,
                                  isExcluded: attribute?.isExcluded || false,
                              }
                          }else{
                              const trimmedValues = attribute.attributeValues.filter((attr) =>  attr?.name?.trim() !== "" || attr?.name !== "" )?.map((attrVal) => attrVal?.name);
                              const uniqueCustomAttributes = [...new Set(
                                  [...dynamicCustomAttributes[attribute.customAttributeRef].attributeValues, ...trimmedValues]
                              )];
                              const originalAttributeValues = [...dynamicCustomAttributes[attribute.customAttributeRef].attributeObjects, ...validCustomAttributes];
                              const uniqueNamesMap = new Map();
                              dynamicCustomAttributes[attribute.customAttributeRef].attributeValues = uniqueCustomAttributes;
                              dynamicCustomAttributes[attribute.customAttributeRef].checkedValues = new Array(uniqueCustomAttributes.length).fill(false);
                              dynamicCustomAttributes[attribute.customAttributeRef].attributeObjects = originalAttributeValues.filter(obj => {
                                  if (!uniqueNamesMap.has(obj.name)) {
                                      uniqueNamesMap.set(obj.name, true);
                                      return true;
                                  }
                                  return false;
                              });
                              dynamicCustomAttributes[attribute.customAttributeRef].isExcluded = attribute?.isExcluded || false;
                          }
                      }else{
                          return;
                      }
                  })
              }else{
                  return;
              }
          })
      }else{
          return;
      }
  })

  return Object.values(dynamicCustomAttributes);
}

export const createValuesIdsArray = (arr, boolArr, isExcluded) => {
  let includeItemRes = [];
  let exludeItemRes = [];
  for (let i = 0; i < boolArr.length; i++) {
      if (boolArr[i] && !isExcluded) {
          includeItemRes.push(arr[i]?._id);
      }else if(boolArr[i] && isExcluded){
          exludeItemRes.push(arr[i]?._id)
      }
  }
  return ({
      includeItemRes, 
      exludeItemRes
  });
}