import { getServiceFee } from "../../common/utils";
import * as actionTypes from "../action-types";

export const setCartLoading = (value) => {
  return {
    type: actionTypes.SET_CART_LOADING,
    payload: value,
  };
};

export const emptyCart = () => {
  return {
    type: actionTypes.EMPTY_CART_ACTION,
  };
};

export const getPlatformFee = (amount, outlet) => {
  if (!outlet) return 0;
  return platformFee(outlet.noqFee, amount);
};

export function getTipAmount(subTotal, tip, tipType) {
  if (tip && tip !== null && tipType === "percentage") {
    return (subTotal * tip) / 100;
  } else if (tip && tip !== null && tipType === "flat") {
    return tip;
  }
  return 0;
}


export const updateCart = (cart) => {
  return async (dispatch, getState) => {
    let { user, storeData } = getState();
    const { items } = cart;

    let totalAmount = 0;
    let vatTotal = 0;
    if (items) {
      items &&
        items.forEach((item) => {
          totalAmount += item.subTotal;
          vatTotal += (item.subTotal * item.vat) / 100;
        });
    }
    cart.subTotal = totalAmount;
    const serviceCharge = getServiceFee(totalAmount, storeData, user);
    totalAmount += serviceCharge;
    cart.vatTotal = vatTotal;
    
    if (cart.tip && cart.tipType && cart.tip > 0) {
      let tempTipAmount = 0;
      if (cart.tipType === "Percentage") {
        tempTipAmount = (cart.tip * cart.subTotal) / 100;
      } else if (cart.tipType === "Flat") {
        tempTipAmount = cart.tip;
      }
      cart.tipAmount = tempTipAmount;
      totalAmount += tempTipAmount;
    }

    cart.totalAmount = round2digit(totalAmount);

    if(storeData.isMicroserviceActive) {
      const tempData = calculateItemLevelTaxV3(cart);
      if (tempData) {
        cart = { ...tempData };
      }
    }
    dispatch({
      type: actionTypes.ADD_ITEMS_CART_ACTION,
      payload: { ...cart, userDetails: {
        ...cart.userDetails,
      } },
  });
  }
}

export const addItemsToCartV1 = (itemDetails) => {
  return async (dispatch, getState) => {
    dispatch(setCartLoading(true));
    itemDetails.cartSKU = createCartSKU(itemDetails);

    let { cart, storeData, user } = getState();
    const matchRef = () => {
      let refMismatch = false;
      if (cart) {
        if (cart.vendorRef && cart.vendorRef !== storeData._id) {
          dispatch(emptyCart());
          refMismatch = true;
        }
      }
      return refMismatch;
    };

    const checkMenuItems = () => {
      const { items } = cart;
      let isItemFound = false;
      let itemIndex = -1;
      itemDetails &&
        items.forEach((itemData, index) => {
          if (itemData.cartSKU === itemDetails.cartSKU) {
            isItemFound = true;
            itemIndex = index;
          }
        });
      return { isItemFound, itemIndex };
    };

    const isRefMatched = matchRef();

    if (
      !isRefMatched &&
      cart &&
      Object.keys(cart).length > 0 &&
      cart.items &&
      cart.items.length > 0
    ) {
      let { items } = cart;
      const checkedItem = checkMenuItems();

      if (checkedItem.isItemFound) {
        if (items[checkedItem.itemIndex].quantity + itemDetails.quantity) {
          items = items.map((itemData, index) => {
            if (index === checkedItem.itemIndex) {
              return {
                ...itemData,
                assignedModifiers: itemDetails.assignedModifiers,
                itemDisplayName: itemDetails.itemDisplayName,
                variants: itemDetails.variants,
                itemName : itemDetails.itemName,
                quantity: itemData.quantity + itemDetails.quantity,
                price: itemDetails.price,
                taxesNew: itemDetails.taxesNew,
                isItemTaxable: itemDetails.isItemTaxable,
                inclusiveTaxTotal: 0,
                exclusiveTaxTotal: 0,
                subTotal:
                  itemDetails.price *
                  (itemData.quantity + itemDetails.quantity),
              };
            } else {
              return itemData;
            }
          });
        } else {
          items = items.filter((_, index) => index !== checkedItem.itemIndex);
        }
        cart.items = items;
      } else {
        items.push(formatCartItem(itemDetails));
        cart.items = items;
      }

      let totalAmount = 0;
      let vatTotal = 0;

      if (items) {
        items.forEach((item) => {
          totalAmount += item.subTotal;
          vatTotal += (item.subTotal * item.vat) / 100;
        });
      }

      cart.subTotal = totalAmount;
      const serviceCharge = getServiceFee(totalAmount, storeData, user);
      cart.serviceCharge = serviceCharge;
      totalAmount += serviceCharge;
      cart.vatTotal = vatTotal;

      if (cart.tip && cart.tipType && cart.tip > 0) {
        let tempTipAmount = 0;
        if (cart.tipType === "Percentage") {
          tempTipAmount = (cart.tip * cart.subTotal) / 100;
        } else if (cart.tipType === "Flat") {
          tempTipAmount = cart.tip;
        }
        cart.tipAmount = tempTipAmount;
        totalAmount += tempTipAmount;
      }
      cart.totalAmount = totalAmount;
      if(storeData.isMicroserviceActive) {
          const tempData = calculateItemLevelTaxV3(cart);
          if (tempData) {
            cart = { ...tempData };
          }
      }

    } else {
      // const platformFee = getPlatformFee(itemDetails.price * itemDetails.quantity, storeData);
      let newCart = {};
      newCart.items = [formatCartItem(itemDetails)];
      let cartTotalAmount = 0;
      let cartTipAmount = 0;
      const serviceCharge = getServiceFee(
        itemDetails.price * itemDetails.quantity,
        storeData,
        user
      );
      cartTotalAmount += newCart.items[0].subTotal + serviceCharge;
      if (user && user.preSetTipAmount > 0 && user.preSetTipType) {
        cartTipAmount += getTipAmount(
          newCart.items[0].subTotal,
          user.preSetTipAmount,
          user.preSetTipType.toLowerCase()
        );
        cartTotalAmount += cartTipAmount;
        newCart.tip = user.preSetTipAmount;
        newCart.tipType = user.preSetTipType;
        newCart.tipAmount = cartTipAmount;
      }
      newCart = {
        ...newCart,
        subTotal: newCart.items[0].subTotal,
        totalAmount: round2digit(cartTotalAmount),
        vatTotal: newCart.items[0].subTotal * newCart.items[0].vat * 0.01,
        vendorRef: storeData._id,
        currency: itemDetails.currency || "gbp",
        serviceCharge: serviceCharge,
        discountAmount: 0,
        absoluteMinimumOrder: user?.absoluteMinOrder,
        serviceType: "kiosk",
        type: "KIOSK",
        serviceTypeDisplayName: "Kiosk",
        kioskUserId: user?.id,
      };

      cart = newCart;
      if(storeData.isMicroserviceActive) {
        const tempData = calculateItemLevelTaxV3(cart);
        if (tempData) {
          cart = { ...tempData };
        }
      }
    }
    dispatch({
      type: actionTypes.ADD_ITEMS_CART_ACTION,
      payload: { ...cart },
    });
    dispatch(setCartLoading(false));
  };
};

export const updateItemFromCart = (itemIndex, itemDetails) => {
  return async (dispatch, getState) => {
    dispatch(setCartLoading(true));
    let { cart, storeData, user } = getState();
    const updatedItems = cart.items.filter((_, index) => {
      return index !== itemIndex;
    });

    let totalAmount = 0;

    if (updatedItems) {
      updatedItems.map((item) => {
        return (totalAmount += item.subTotal);
      });
    }

    cart.subTotal = totalAmount;

    if (cart.tip && cart.tipType && cart.tip > 0) {
      let tempTipAmount = 0;
      if (cart.tipType === "Percentage") {
        tempTipAmount = (cart.tip * cart.subTotal) / 100;
      } else if (cart.tipType === "Flat") {
        tempTipAmount = cart.tip;
      }
      cart.tipAmount = tempTipAmount;
      totalAmount += tempTipAmount;
    }

    cart.items = updatedItems;
    cart.totalAmount = totalAmount;
    // /***********Add item starts here ************/

    itemDetails.cartSKU = createCartSKU(itemDetails);

    const matchRef = () => {
      let refMismatch = false;
      if (cart && cart.vendorRef && cart.vendorRef !== storeData?._id) {
        dispatch(emptyCart());
        refMismatch = true;
      }
      return refMismatch;
    };

    const checkMenuItems = () => {
      const { items } = cart;
      let isItemFound = false;
      let itemIndex = -1;
      itemDetails &&
        items.forEach((itemData, index) => {
          if (itemData.cartSKU === itemDetails.cartSKU) {
            isItemFound = true;
            itemIndex = index;
          }
        });
      return { isItemFound, itemIndex };
    };

    const isRefMatched = matchRef();

    if (
      !isRefMatched &&
      cart &&
      Object.keys(cart).length > 0 &&
      cart.items &&
      cart.items.length > 0
    ) {
      let { items } = cart;
      const checkedItem = checkMenuItems();

      if (checkedItem.isItemFound) {
        if (items[checkedItem.itemIndex].quantity + itemDetails.quantity) {
          items = items.map((itemData, index) => {
            if (index === checkedItem.itemIndex) {
              return {
                ...itemData,
                quantity: itemData.quantity + itemDetails.quantity,
                price: itemDetails.price,
                subTotal:
                  itemDetails.price *
                  (itemData.quantity + itemDetails.quantity),
              };
            } else {
              return itemData;
            }
          });
        } else {
          items = items.filter((_, index) => index !== checkedItem.itemIndex);
        }
        cart.items = items;
      } else {
        items.push(formatCartItem(itemDetails));
        cart.items = items;
      }

      let totalAmount = 0;
      let vatTotal = 0;

      if (items) {
        items &&
          items.forEach((item) => {
            totalAmount += item.subTotal;
            vatTotal += (item.subTotal * item.vat) / 100;
          });
      }

      cart.subTotal = totalAmount;
      const serviceCharge = getServiceFee(totalAmount, storeData, user);
      totalAmount += serviceCharge;
      cart.vatTotal = vatTotal;

      // if (
      //   cart.tip &&
      //   cart.tip.type &&
      //   cart.tip.type === "Percentage" &&
      //   cart.tip.value > 0
      // ) {
      //   cart.tip.amount = (cart.tip.value * cart.subTotal) / 100;
      // }
      // cart.totalAmount = totalAmount;
      if (cart.tip && cart.tipType && cart.tip > 0) {
        let tempTipAmount = 0;
        if (cart.tipType === "Percentage") {
          tempTipAmount = (cart.tip * cart.subTotal) / 100;
        } else if (cart.tipType === "Flat") {
          tempTipAmount = cart.tip;
        }
        cart.tipAmount = tempTipAmount;
        totalAmount += tempTipAmount;
      }
      cart.totalAmount = totalAmount;
    } else {
      //   const platformFee = getPlatformFee(
      //     itemDetails.price * itemDetails.quantity,
      //     outletData
      //   );
      let newCart = {};
      newCart.items = [formatCartItem(itemDetails)];
      let cartTotalAmount = 0;
      let cartTipAmount = 0;
      const serviceCharge = getServiceFee(
        itemDetails.price * itemDetails.quantity,
        storeData,
        user
      );
      cartTotalAmount += newCart.items[0].subTotal + serviceCharge;
      if (user && user.preSetTipAmount > 0 && user.preSetTipType) {
        cartTipAmount += getTipAmount(
          newCart.items[0].subTotal,
          user.preSetTipAmount,
          user.preSetTipType.toLowerCase()
        );
        cartTotalAmount += cartTipAmount;
        newCart.tip = user.preSetTipAmount;
        newCart.tipType = user.preSetTipType;
        newCart.tipAmount = cartTipAmount;
      }
      newCart = {
        ...newCart,
        subTotal: newCart.items[0].subTotal,
        totalAmount: round2digit(cartTotalAmount),
        vatTotal: newCart.items[0].subTotal * newCart.items[0].vat * 0.01,
        vendorRef: storeData._id,
        currency: itemDetails?.currency || "gbp",
        absoluteMinimumOrder: user.absoluteMinimumOrder,
        serviceType: "kiosk",
        serviceCharge: serviceCharge,
        discountAmount: 0,
        // serviceType: serviceData.serviceType,
        type: "KIOSK",
        serviceTypeDisplayName: "Kiosk",
        kioskUserId: user?.id,
      };
      cart = newCart;
    }

    if(storeData.isMicroserviceActive) {
      const tempData = calculateItemLevelTaxV3(cart);
      if (tempData) {
        cart = { ...tempData };
      }
    }

    dispatch({
      type: actionTypes.ADD_ITEMS_CART_ACTION,
      payload: { ...cart },
    });
    dispatch(setCartLoading(false));
  };
};

export const deleteItemFromCart = (itemIndex) => {
  return async (dispatch, getState) => {
    dispatch(setCartLoading(true));
    let { cart, storeData, user } = getState();

    const updatedItems = cart.items.filter((itemdata, index) => {
      return index !== itemIndex;
    });

    let totalAmount = 0;

    if (updatedItems) {
      updatedItems.map((item) => {
        return (totalAmount += item.subTotal);
      });
    }

    cart.subTotal = totalAmount;
    const serviceCharge = getServiceFee(totalAmount, storeData, user);
    cart.serviceCharge = serviceCharge;
    totalAmount += serviceCharge;

    if (
      cart.tip &&
      cart.tip.type &&
      cart.tip.type === "Percentage" &&
      cart.tip.value > 0
    ) {
      cart.tip.amount = (cart.tip.value * cart.subTotal) / 100;
    }

    cart.items = updatedItems;
    cart.totalAmount = totalAmount;

    if (cart && cart.items && cart.items.length) {
      if(storeData.isMicroserviceActive) {
        const tempData = calculateItemLevelTaxV3(cart);
        if (tempData) {
          cart = { ...tempData };
        }
      }
      dispatch({
        type: actionTypes.ADD_ITEMS_CART_ACTION,
        payload: { ...cart },
      });
    } else {
      dispatch(emptyCart());
    }
    dispatch(setCartLoading(false));
  };
};

export const setQuantityLoaderInline = (value) => {
  return {
    type: actionTypes.SET_QUANTITY_LOADER,
    payload: value,
  };
};

export const setQuantityLoader = (value) => {
  return async (dispatch) => {
    dispatch(setQuantityLoaderInline(value));
  };
};

export const injectTipToCart = (value) => {
  return async (dispatch, getState) => {
    dispatch(setCartLoading(true));
    let { cart, user, storeData } = getState();
    const { items } = cart;

    let totalAmount = 0;
    let vatTotal = 0;
    if (items) {
      items &&
        items.forEach((item) => {
          totalAmount += item.subTotal;
          vatTotal += (item.subTotal * item.vat) / 100;
        });
    }
    cart.subTotal = totalAmount;
    const serviceCharge = getServiceFee(totalAmount, storeData, user);
    totalAmount += serviceCharge;
    cart.vatTotal = vatTotal;
    let tempTipAmount = 0;
    if (value && value !== null && value.tip && value.type && value.tip > 0) {
      if (value.type === "Percentage") {
        tempTipAmount += (value.tip * cart.subTotal) / 100;
      } else if (value.type === "Flat") {
        tempTipAmount += value.tip;
      }
      cart.tipAmount = tempTipAmount;
      totalAmount += tempTipAmount;
      cart.tip = value.tip;
      cart.tipType = value.type;
    } else {
      delete cart.tip;
      delete cart.tipType;
      delete cart.tipAmount;
    }
    cart.totalAmount = round2digit(totalAmount);

    if(storeData.isMicroserviceActive) {
      const tempData = calculateItemLevelTaxV3(cart);
      if (tempData) {
        cart = { ...tempData };
      }
    }

    dispatch({
      type: actionTypes.ADD_ITEMS_CART_ACTION,
      payload: { ...cart },
    });
    dispatch(setCartLoading(false));
  };
};

export const injectCheckoutDetailsToCart = (userDetails) => {
  return (dispatch, getState) => {
    dispatch(setCartLoading(true));
    let { cart } = getState();
    dispatch({
      type: actionTypes.ADD_ITEMS_CART_ACTION,
      payload: {
        ...cart,
        userDetails: {
          ...userDetails,
        },
      },
    });
    dispatch(setCartLoading(false));
  };
};

const platformFee = (noqFee, amount) => {
  let platformFee = 0;
  const platformFix = parseFloat(noqFee.platformFix);
  const platform = parseFloat(noqFee.platform);
  if (platform && platformFix) {
    platformFee = (platform * amount) / 100 + platformFix;
  } else if (platform) {
    platformFee = (platform * amount) / 100;
  } else if (platformFix) {
    platformFee = platformFix;
  }
  return +platformFee.toFixed(2);
};

export const round2digit = (numb) =>
  Math.round((numb + Number.EPSILON) * 100) / 100;

export const getMonth = (dateObj) => {
  if (!dateObj) return "";
  if (typeof dateObj === "string") dateObj = new Date(dateObj);
  return `${dateObj.getDate()}/${
    dateObj.getMonth() + 1
  }/${dateObj.getFullYear()}`;
};

export const getTime = (dateObj) => {
  if (!dateObj) return "";
  if (typeof dateObj === "string") dateObj = new Date(dateObj);
  var hours = dateObj.getHours();
  var minutes = dateObj.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

/**
 * @description function to format items for add into cart
 * @param {Object} itemDetails
 * @returns {Object}
 */
const formatCartItem = (itemDetails) => {
  const assignedMenuItemRef = itemDetails.assignedRef
    ? itemDetails.assignedRef
    : itemDetails._id;
  return {
    assignedRef: assignedMenuItemRef,
    itemRef: itemDetails.itemRef,
    name: itemDetails.itemName,
    imageUrl: itemDetails.imageUrl ? itemDetails.imageUrl : "",
    outletRef: itemDetails.outletRef,
    vendorRef: itemDetails.vendorRef,
    menuRef: itemDetails.menuRef,
    category: itemDetails.category,
    itemName: itemDetails.itemName,
    itemPrice: itemDetails.itemPrice,
    quantity: itemDetails.quantity,
    specialInstructions: itemDetails.specialInstructions
      ? itemDetails.specialInstructions
      : "",
    cartSKU: itemDetails.cartSKU,
    printerRef: itemDetails.printerRef ? itemDetails.printerRef : null,
    assignedPrinters:
      itemDetails.assignedPrinters && itemDetails.assignedPrinters.length
          ? itemDetails.assignedPrinters || []
          : [],
    kdsRef:
      itemDetails.kds && itemDetails.kds.length
        ? itemDetails.kds[0].kdsRef
        : null,
    kdsGroupRef:
      itemDetails.kds && itemDetails.kds.length
        ? itemDetails.kds[0].kdsGroupRef
        : null,
    variants: itemDetails && itemDetails.variants ? itemDetails.variants : [],
    assignedModifiers: itemDetails.assignedModifiers,
    subTotal: itemDetails.price * itemDetails.quantity,
    price: itemDetails.price,
    vat: itemDetails && itemDetails.vat ? itemDetails.vat : 0,
    description:
      itemDetails && itemDetails.description ? itemDetails.description : "",
    menu: itemDetails.menu,
    menuGroup: itemDetails.menuGroup,
    assignedMenuItemRef: assignedMenuItemRef,
    hasVariants:
      itemDetails && itemDetails.hasVariants ? itemDetails.hasVariants : false,
    allergens:
      itemDetails && itemDetails.allergens ? itemDetails.allergens : [],
    kiloCalories:
      itemDetails && itemDetails.kiloCalories ? itemDetails.kiloCalories : 0,
    diet: itemDetails && itemDetails.diet ? itemDetails.diet : [],
    takeawayVat: itemDetails?.takeawayVat ?? 0,
    warehouseRef: itemDetails.warehouseRef || null,
    parentItemRef: itemDetails.parentItemRef || null,
    taxesNew: itemDetails.taxesNew,
    isItemTaxable: itemDetails.isItemTaxable,
    inclusiveTaxTotal: 0,
    exclusiveTaxTotal: 0,
    grossProfit: itemDetails.grossProfit,
    markupMargin: itemDetails.markupMargin,
    modifiers: itemDetails.modifiers,
  };
};

const createCartSKU = (itemDetails) => {
  let cartSKU = `${itemDetails.assignedRef || itemDetails._id}`;

  if (itemDetails && itemDetails.variants && itemDetails.variants.length) {
    for (let i = 0; i < itemDetails.variants.length; i++) {
      if (itemDetails.variants[i].isSelected) {
        cartSKU += `-${itemDetails.variants[i]._id}`;
        break;
      }
    }
  }

  let tempSKU = "";

  if (
    itemDetails &&
    itemDetails.assignedModifiers &&
    itemDetails.assignedModifiers.length
  ) {
    for (let i = 0; i < itemDetails.assignedModifiers.length; i++) {
      const maxSelect =
        itemDetails.assignedModifiers.selectionType === "Multiple"
          ? itemDetails.assignedModifiers.maximumSelect
          : itemDetails.assignedModifiers.selectionType === "Single"
          ? 1
          : 999;
      let currentSelection = 0;
      for (
        let j = 0;
        j < itemDetails.assignedModifiers[i].modifiers.length;
        j++
      ) {
        if (itemDetails.assignedModifiers[i].modifiers[j].isSelected) {
          currentSelection += 1;
          tempSKU += `-${itemDetails.assignedModifiers[i].modifiers[j]._id}-${itemDetails.assignedModifiers[i].modifiers[j].quantity}`;
          if (currentSelection === maxSelect) {
            break;
          }
        }
      }
    }
    cartSKU += `-${tempSKU}`;
  }
  return cartSKU;
};

const calculateItemLevelTaxV3 = (cart) => {
  let subTotal = calculateSubTotal(cart.items || []);
  cart.inclusiveTaxTotal = 0;
  cart.exclusiveTaxTotal = 0;
  cart.vatTotal = 0;
  cart.counterDineType = cart.counterDineType ? cart.counterDineType : "EAT_IN"
  for (let i = 0; i < cart.items.length; i++) {
      let subTotalAfterDiscount = round2digit(
          cart.items[i].itemPrice * cart.items[i].quantity,
      );

      // calculate item tax here
      const cartItem = calculateCartTaxesV3({
          cartItem: cart.items[i],
          subTotalAfterDiscount,
          counterDineType: cart.counterDineType,
      });

      cart.items[i] = { ...cartItem };
      cart.inclusiveTaxTotal += cartItem.inclusiveTaxTotal;
      cart.exclusiveTaxTotal += cartItem.exclusiveTaxTotal;
      cart.vendorTotal += cartItem.exclusiveTaxTotal;
      cart.totalAmount += cartItem.exclusiveTaxTotal;
      cart.totalAmountAfterDiscount += cartItem.exclusiveTaxTotal;      

      if (cart.items[i].modifiers && cart.items[i].modifiers.length) {
          for (let j = 0; j < cart.items[i].modifiers.length; j++) {
              const mdf = cart.items[i].modifiers[j];
              mdf.subTotal = round2digit(
                  mdf.price * mdf.quantity * cart.items[i].quantity,
              );

              let mdfSubtotalAfterDiscount = mdf.subTotal || 0;

              // if modifier is not item level
              if (mdf.itemRef === null || !mdf.itemRef) {
                  mdf.taxesNew = cart.items[i].taxesNew;
              }

              // calculate item tax here
              const updateModifier = calculateCartTaxesV3({
                  cartItem: mdf,
                  subTotalAfterDiscount: mdfSubtotalAfterDiscount,
                  counterDineType: cart.counterDineType,
              });

              cart.items[i].modifiers[j] = { ...updateModifier };
              cart.inclusiveTaxTotal += updateModifier.inclusiveTaxTotal;
              cart.exclusiveTaxTotal += updateModifier.exclusiveTaxTotal;
              cart.vendorTotal += updateModifier.exclusiveTaxTotal;
              cart.totalAmount += updateModifier.exclusiveTaxTotal;
              cart.totalAmountAfterDiscount +=
                  updateModifier.exclusiveTaxTotal;
          }
      }
  }
  const data = cumulateCartTaxesV3(cart);
  if (data) {
      cart = data;
  }

  return { ...cart };
};

const calculateExclusiveTax = (total, taxPercentage) => {
  return (total * taxPercentage) / 100;
};

const calculateInclusiveTax = (total, taxPercentage) => {
  return total * (taxPercentage / (100 + taxPercentage));
};

const calculateSubTotal = (items) => {
  let subtotal = 0;

  items.map((item) => {
      subtotal += item.subTotal;
  });

  return subtotal;
};

const calculateCartTaxesV3 = ({ cartItem, subTotalAfterDiscount, counterDineType }) => {
  // Make a deep copy of the cartItem to avoid modifying the original object
  cartItem = JSON.parse(JSON.stringify(cartItem));

  // Initialize tax-related variables
  let inclusiveTaxTotal = 0,
      exclusiveTaxTotal = 0;
  // If item not taxable then all taxes will be 0
  if (cartItem?.isItemTaxable === false) {
      cartItem.inclusiveTaxTotal = inclusiveTaxTotal;
      cartItem.exclusiveTaxTotal = exclusiveTaxTotal;
      cartItem.vat = 0;
      cartItem.takeawayVat = 0;
      return cartItem;
  }

  // Calculate inclusive taxes
  let sumOfTaxRate = 0;
  cartItem?.taxesNew?.forEach((tax) => {
    const isExemptible = tax.isExemptible;
    const diningOptions = tax.exemptionDetails?.diningOptions || [];
    if (
      isExemptible &&
      counterDineType &&
      diningOptions.length &&
      diningOptions.includes(counterDineType)
    ) {
      tax.taxAmount = 0;
    } else {
      const isTaxInclusive = tax?.isTaxInclusive;
      if (isTaxInclusive) {
          sumOfTaxRate += tax?.taxRate;
      }
    }
  });

  inclusiveTaxTotal = calculateInclusiveTax(
      subTotalAfterDiscount,
      sumOfTaxRate,
  );

  cartItem?.taxesNew?.forEach((tax) => {
    const isExemptible = tax.isExemptible;
    const diningOptions = tax.exemptionDetails?.diningOptions || [];

    if (
      isExemptible &&
      counterDineType &&
      diningOptions.length &&
      diningOptions.includes(counterDineType)
    ) {
      tax.taxAmount = 0;
    } else {
      const isTaxInclusive = tax?.isTaxInclusive;
      if (isTaxInclusive) {
        const taxAmount =
            (subTotalAfterDiscount * tax.taxRate) / (100 + sumOfTaxRate);
        tax.taxAmount = taxAmount;
      }
    }
  });

  // Update subtotal
  subTotalAfterDiscount -= inclusiveTaxTotal;

  // Calculate exclusive taxes
  cartItem?.taxesNew?.forEach((tax) => {
      const isExemptible = tax.isExemptible;
    const diningOptions = tax.exemptionDetails?.diningOptions || [];

    if (
      isExemptible &&
      counterDineType &&
      diningOptions.length &&
      diningOptions.includes(counterDineType)
    ) {
      tax.taxAmount = 0;
    } else {
      const isTaxExclusive = tax?.isTaxInclusive === false;
      if (isTaxExclusive) {
        const taxAmount = calculateExclusiveTax(
          subTotalAfterDiscount,
          tax?.taxRate,
        );
        exclusiveTaxTotal += taxAmount;
        tax.taxAmount = taxAmount;
      }
    }
  });

  // Update clonedCartItem with tax properties
  cartItem.inclusiveTaxTotal = inclusiveTaxTotal;
  cartItem.exclusiveTaxTotal = exclusiveTaxTotal;
  cartItem.vat = 0;
  cartItem.takeawayVat = 0;

  // Return the updated clonedCartItem
  return cartItem;
};

const cumulateCartTaxesV3 = (cart) => {
  const taxesArray = [];

  cart?.items?.forEach((item) => {
      taxesArray.push(...item?.taxesNew);
      item?.modifiers?.forEach((modifier) => {
          taxesArray.push(...modifier?.taxesNew);
      });
  });

  if (taxesArray?.length) {
      const groupedData = groupAndSumTaxAmountV3(taxesArray);
      cart.taxesNew = groupedData;
  } else {
      cart.taxesNew = [];
  }
  return cart;
};

const groupAndSumTaxAmountV3 = (data) => {
  const groupedData = {};

  data.forEach((item) => {
      const taxId = item.taxRef || item._id;

      if (groupedData[taxId]) {
          groupedData[taxId].taxAmount += item.taxAmount;
      } else {
          groupedData[taxId] = {
              taxRef: taxId,
              taxName: item?.taxName,
              taxRate: item?.taxRate,
              isTaxInclusive: item?.isTaxInclusive,
              taxAmount: item?.taxAmount,
          };
      }
  });

  return Object.values(groupedData);
};